

import { defineComponent, onMounted, onUpdated, ref } from "vue";
import { ColorPicker } from 'vue-color-kit';

export default defineComponent( {
    name: 'CustomColorPicker',
    components: { ColorPicker },
    props: {
        value: {
            type: String
        }
    },
    emits: [ "update" ],
    setup( props, { emit } ) {
        const currValue = ref( "#000000" );
        const show = ref( false );

        onMounted( () => {
            if( props.value ) {
                currValue.value = props.value;
            }
        } );

        onUpdated( () => {
            if( props.value ) {
                currValue.value = props.value;
            }
        } );

        function update( event ) {
            currValue.value = event.hex;
            emit( "update", event.hex );
        }

        function toggle() {
            show.value = !show.value;
        }

        function hide() {
            show.value = false;
        }

        return {
            currValue,
            show,
            update,
            toggle,
            hide
        };
    }
} );
