import { ConfigProp } from "@/config/ConfigProp";

export class ModuleConfig {
    public static get Config(): { [key: string]: ConfigProp } {
        return {
            "type": {
                "type": "string",
                "format": "enum",
                "values": [
                    "gallery",
                    "html",
                    "events",
                    "partners"
                ],
                "required": true,
                "hidden": true
            },
            "title": {
                "type": "object",
                "format": "header"
            },
            "style": {
                "type": "number",
                "format": "style",
                "required": true
            },
            "name": {
                "type": "string",
                "maxLength": 20,
                "required": true
            },
            "inMenu": {
                "type": "boolean",
                "default": true,
                "required": true
            },
            "config": {
                "type": "object",
                "format": "config",
                "required": true
            }
        };
    }
}