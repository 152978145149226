

import { defineComponent, getCurrentInstance, PropType } from "vue";
import { IMenuItem } from "@/utils/interfaces/IMenuItem";

export default defineComponent( {
    name: 'MenuLink',
    components: {},
    props: {
        editor: {
            type: Boolean,
            default: () => false
        },
        item: {
            type: Object as PropType<IMenuItem>
        },
        linkClass: {
            type: String
        }
    },
    setup( props, { emit } ) {
        const app = getCurrentInstance();
        const urlRegex = new RegExp( "(?:\/)?([^#]*)#*(.*)", 'g' );

        function isExternalLink( link: string ): boolean {
            try {
                return new URL( link ).origin !== location.origin;
            } catch {
                return false;
            }
        }

        function setSite( link: string ) {
            let page: string = link.replaceAll( urlRegex, '$1' );
            app.appContext.config.globalProperties.editorPage.value = page === "" ? "/" : page;
            app.appContext.config.globalProperties.editorElement.value = link.replaceAll( urlRegex, '$2' );
        }

        return {
            isExternalLink,
            setSite,
        };
    }
} );
