import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100 gallery-1"
}
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "img-indicator-wrapper"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.config.images.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "w-100 mx-auto",
            src: _ctx.config.images[_ctx.currImg].itemSrc,
            style: _normalizeStyle([{"object-fit":"cover"}, {height:_ctx.config.height, maxWidth: _ctx.config.maxWidth, transform: _ctx.skewY}]),
            onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTouchStart && _ctx.handleTouchStart(...args))),
            onTouchend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleTouchEnd && _ctx.handleTouchEnd(...args))),
            onTouchmove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleTouchMove && _ctx.handleTouchMove(...args)))
          }, null, 44, _hoisted_3)
        ]),
        (_ctx.config.manual)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.images, (img, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "img-indicator-btn",
                  key: img,
                  onClick: ($event: any) => (_ctx.setImg(index))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["img-indicator", {active: index === _ctx.currImg}])
                  }, null, 2)
                ], 8, _hoisted_5))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}