

import { defineComponent, PropType } from "vue";
import { ILegalConfig } from "@/utils/interfaces/ILegalConfig";

export default defineComponent( {
    name: 'LegalNotice',
    components: {},
    props: {
        legal: {
            type: Object as PropType<ILegalConfig>
        }
    },
    setup() {
    }
} );
