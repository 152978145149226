

import { defineComponent, onMounted, onUpdated, PropType, Ref, ref } from "vue";
import { ConfigProp } from "@/config/ConfigProp";
import { useI18n } from "vue-i18n";
import InputText from "primevue/inputtext";
import Icon from "@/modules/general/Icon.vue";
import IconPicker from "@/modules/editor/IconPicker.vue";
import Fieldset from "primevue/fieldset";
import GalleryModal from "@/modules/editor/GalleryModal.vue";
import HeaderConfigurator from "@/modules/editor/HeaderConfigurator.vue";
import GalleryConfigurator from "@/modules/editor/GalleryConfigurator.vue";
import { nanoid } from "nanoid";
import { getConfig, getContainerConfig, getModuleConfig } from "@/config/components/ConfigLoader";
import Button from "primevue/button";
import Image from 'primevue/image';

interface IProps {
    property?: ConfigProp,
    value?: any,
    id?: string,
    parentInformation?: any
}

enum EditMode {
    Easy,
    Advanced
}

const lodash = require( "lodash" );

export default defineComponent( {
    name: 'ObjectConfig',
    components: {
        GalleryConfigurator,
        HeaderConfigurator,
        GalleryModal,
        Fieldset,
        IconPicker,
        Icon,
        InputText,
        Button,
        Image
    },
    props: {
        property: {
            type: Object as PropType<ConfigProp>
        },
        value: {},
        id: {
            type: String
        },
        parentInformation: {},
    },
    emits: [ "change", "delete" ],
    setup( props: IProps, { emit } ) {
        const currValue = ref( null );
        const i18n = useI18n();
        const rndId = ref( "id" + nanoid() );
        const mode: Ref<EditMode> = ref( EditMode.Easy );

        onMounted( () => {
            currValue.value = lodash.cloneDeep( props.value );
        } );

        function updateConfig( index, value ) {
            if( index != undefined ) {
                currValue.value[index] = value;
            } else {
                if( value ) {
                    currValue.value = value;
                }
            }
            emit( 'change', currValue.value );
        }

        function deleteConfig() {
            currValue.value = null;
            emit( 'delete' );
        }

        function changeMode() {
            if( mode.value === EditMode.Easy ) {
                mode.value = EditMode.Advanced;
            } else {
                mode.value = EditMode.Easy;
            }
        }

        function getConfigByModule( type: string, style: number ) {
            return {
                type: "object",
                properties: getModuleConfig( type, style )
            };
        }

        function getConfigByContainer( style: number ) {
            return {
                type: "object",
                properties: getContainerConfig( style )
            };
        }

        function getConfigByObject( prop: ConfigProp, item ): ConfigProp {
            let conf = getConfig( prop.format );
            if( conf === null ) {
                conf = prop.properties;
            }

            return {
                default: prop.default,
                format: prop.format,
                hidden: prop.hidden,
                items: prop.items,
                maxLength: prop.maxLength,
                properties: conf,
                required: prop.required,
                type: prop.type
            };
        }

        return {
            EditMode,
            currValue,
            mode,
            rndId,
            updateConfig,
            deleteConfig,
            changeMode,
            getConfigByModule,
            getConfigByContainer,
            getConfigByObject
        };
    }
} );
