

import { defineComponent, getCurrentInstance, onMounted, PropType, Ref, ref } from "vue";
import { EventApi, EventSearchResponseDto, EventDto } from "@/api";
import { ApiService } from "@/services/ApiService";
import EventCard from "@/modules/events/1/EventCard.vue";
import EventDetailModal from "@/modules/events/1/EventDetailModal.vue";

interface IConf {
    maxEvents: number,
    bgColor: string,
    maxWidth: string
}

export default defineComponent( {
    name: 'Events_1',
    components: { EventDetailModal, EventCard },
    props: {
        config: {
            type: Object as PropType<IConf>
        },
        id: {
            type: String
        }
    },
    setup( props: any ) {
        const eventApi: EventApi = ApiService.wrap( EventApi );
        const upcomingEvents: Ref<EventSearchResponseDto> = ref( null );
        const currEvent: Ref<EventDto> = ref( null );
        const instance = getCurrentInstance();
        const club = instance.appContext.config.globalProperties.config.general.club;

        onMounted( () => {
            getUpcomingEvents( new Date() );
        } );

        function getUpcomingEvents( date: Date ) {
            //TODO: use club as where clause
            eventApi.getEvents( {
                "since": date.toISOString().split( 'T' )[0],
                "page": {
                    size: props.config.maxEvents,
                    page: 0
                }
            } )
                    .then( ( r ) => {
                        upcomingEvents.value = r.data;
                    } );
        }

        function selectEvent( event: EventDto ) {
            currEvent.value = event;
        }

        return {
            upcomingEvents,
            currEvent,
            selectEvent
        };
    }
} );
