

import { defineComponent, getCurrentInstance, onMounted, onUpdated, Ref, ref, watch } from "vue";
import GallerySwitch from "@/modules/gallery/GallerySwitch.vue";
import HTMLSwitch from "@/modules/html/HTMLSwitch.vue";
import EventsSwitch from "@/modules/events/EventsSwitch.vue";
import PartnersSwitch from "@/modules/partners/PartnersSwitch.vue";
import Button from "primevue/button";
import { IModule } from "@/utils/interfaces/IModule";

interface Props {
    pages: any;
}

export default defineComponent( {
    name: 'ModuleContainer',
    components: {
        GallerySwitch,
        HTMLSwitch,
        EventsSwitch,
        PartnersSwitch,
        Button
    },
    props: {
        pages: {
            type: Array
        },
        offsetTop: {
            type: String
        }
    },
    setup( props: Props ) {
        const app = getCurrentInstance();
        const editor: boolean = app.appContext.config.globalProperties.editor;
        const modules: Ref<IModule[]> = ref( [] );

        function edit( index: number ) {
            app.appContext.config.globalProperties.openModalEditor.value = index;
        }

        onMounted( () => {
            if( editor ) {
                modules.value = getModulesOfPage( app.appContext.config.globalProperties.editorPage.value );
                watch( app.appContext.config.globalProperties.editorPage, ( newValue, oldValue ) => {
                    modules.value = getModulesOfPage( app.appContext.config.globalProperties.editorPage.value );
                } );
                watch( app.appContext.config.globalProperties.editorElement, ( newValue, oldValue ) => {
                    setTimeout( () => {
                        location.href = '#';
                        location.href = ( '#' + app.appContext.config.globalProperties.editorElement.value );
                    }, 500 );

                } );


            } else {
                modules.value = getModulesOfPage( window.location.pathname );
            }
        } );

        onUpdated( () => {
            modules.value = getModulesOfPage( app.appContext.config.globalProperties.editorPage.value );
        } );

        function getModulesOfPage( pPath ): [] {
            if( !props.pages ) return [];
            let path = pPath;
            if( !path ) {
                path = "/";
            }
            if( path.startsWith( "/edit/" ) || path === "/edit" ) {
                path.replace( "/edit", "" );
            }
            const pathParts = path.split( '/' ).filter( p => p );
            if( pathParts.length == 0 ) return props.pages[0].modules;
            let remainingPaths = props.pages.map( p => p.path );
            let tmpPath = "";

            for( const subPath of pathParts ) {
                tmpPath += '/' + subPath;
                let tmpList = remainingPaths.filter( p => p.startsWith( tmpPath ) || ( '/' + p ).startsWith( tmpPath ) );
                if( tmpList.length < 1 ) break;
                remainingPaths = tmpList;
            }
            return props.pages.find( p => p.path === remainingPaths[0] || ( '/' + p ) === remainingPaths[0] ).modules;
        }

        return {
            editor,
            modules,
            edit
        };
    }
} );
