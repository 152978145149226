export const miIcons = [
    "search",
    "home",
    "account_circle",
    "settings",
    "done",
    "info",
    "check_circle",
    "delete",
    "visibility",
    "shopping_cart",
    "favorite",
    "description",
    "logout",
    "favorite_border",
    "lock",
    "schedule",
    "language",
    "help_outline",
    "face",
    "manage_accounts",
    "filter_alt",
    "verified",
    "event",
    "thumb_up",
    "fingerprint",
    "dashboard",
    "login",
    "calendar_today",
    "list",
    "visibility_off",
    "check_circle_outline",
    "highlight_off",
    "help",
    "question_answer",
    "paid",
    "task_alt",
    "date_range",
    "article",
    "shopping_bag",
    "lightbulb",
    "open_in_new",
    "perm_identity",
    "trending_up",
    "history",
    "credit_card",
    "account_balance",
    "delete_outline",
    "report_problem",
    "fact_check",
    "assignment",
    "verified_user",
    "arrow_right_alt",
    "star_rate",
    "account_balance_wallet",
    "autorenew",
    "analytics",
    "build",
    "view_list",
    "work",
    "print",
    "store",
    "today",
    "delete_forever",
    "admin_panel_settings",
    "lock_open",
    "grade",
    "code",
    "savings",
    "room",
    "watch_later",
    "receipt",
    "update",
    "add_shopping_cart",
    "contact_support",
    "power_settings_new",
    "pets",
    "calendar_month",
    "done_all",
    "explore",
    "bookmark",
    "note_add",
    "bookmark_border",
    "account_box",
    "reorder",
    "pending_actions",
    "shopping_basket",
    "drag_indicator",
    "payment",
    "launch",
    "supervisor_account",
    "pending",
    "zoom_in",
    "touch_app",
    "assessment",
    "thumb_up_off_alt",
    "open_in_full",
    "leaderboard",
    "exit_to_app",
    "done_outline",
    "preview",
    "assignment_ind",
    "card_giftcard",
    "view_in_ar",
    "published_with_changes",
    "work_outline",
    "feedback",
    "timeline",
    "swap_horiz",
    "dns",
    "assignment_turned_in",
    "sync_alt",
    "flight_takeoff",
    "label",
    "stars",
    "book",
    "bug_report",
    "contact_page",
    "pan_tool",
    "space_dashboard",
    "gavel",
    "alarm",
    "cached",
    "translate",
    "tips_and_updates",
    "edit_calendar",
    "supervised_user_circle",
    "android",
    "minimize",
    "accessibility",
    "extension",
    "get_app",
    "record_voice_over",
    "add_task",
    "trending_flat",
    "hourglass_empty",
    "help_center",
    "accessibility_new",
    "thumb_down",
    "rule",
    "sticky_note_2",
    "flutter_dash",
    "source",
    "rocket_launch",
    "dashboard_customize",
    "support",
    "find_in_page",
    "question_mark",
    "settings_applications",
    "close_fullscreen",
    "redeem",
    "ads_click",
    "announcement",
    "view_headline",
    "loyalty",
    "swap_vert",
    "group_work",
    "restore",
    "arrow_circle_right",
    "dangerous",
    "euro_symbol",
    "sensors",
    "compare_arrows",
    "nightlight_round",
    "subject",
    "privacy_tip",
    "disabled_by_default",
    "table_view",
    "https",
    "toc",
    "track_changes",
    "copyright",
    "arrow_circle_up",
    "bookmarks",
    "grading",
    "api",
    "query_builder",
    "perm_media",
    "build_circle",
    "input",
    "zoom_out",
    "view_module",
    "perm_contact_calendar",
    "backup",
    "book_online",
    "open_with",
    "settings_phone",
    "circle_notifications",
    "speaker_notes",
    "card_membership",
    "perm_phone_msg",
    "file_present",
    "label_important",
    "arrow_circle_down",
    "3d_rotation",
    "wysiwyg",
    "pageview",
    "integration_instructions",
    "upgrade",
    "swipe",
    "trending_down",
    "change_history",
    "class",
    "accessible",
    "g_translate",
    "settings_accessibility",
    "currency_exchange",
    "offline_bolt",
    "expand",
    "production_quantity_limits",
    "model_training",
    "settings_backup_restore",
    "aspect_ratio",
    "donut_large",
    "percent",
    "segment",
    "calendar_view_month",
    "view_column",
    "arrow_circle_left",
    "schedule_send",
    "maximize",
    "bookmark_add",
    "alarm_on",
    "thumbs_up_down",
    "settings_ethernet",
    "theaters",
    "view_agenda",
    "thumb_down_off_alt",
    "important_devices",
    "invert_colors",
    "unpublished",
    "open_in_browser",
    "addchart",
    "no_accounts",
    "opacity",
    "commute",
    "youtube_searched_for",
    "history_toggle_off",
    "view_week",
    "tour",
    "system_update_alt",
    "settings_input_antenna",
    "turned_in",
    "mark_as_unread",
    "shop",
    "hide_source",
    "bookmark_added",
    "plagiarism",
    "search_off",
    "flight_land",
    "not_started",
    "assignment_late",
    "donut_small",
    "saved_search",
    "contactless",
    "highlight_alt",
    "view_carousel",
    "settings_input_component",
    "anchor",
    "turned_in_not",
    "mediation",
    "assignment_return",
    "view_quilt",
    "fit_screen",
    "all_inbox",
    "edit_off",
    "toll",
    "settings_remote",
    "flaky",
    "hourglass_full",
    "remove_shopping_cart",
    "hotel_class",
    "shopping_cart_checkout",
    "lock_clock",
    "balance",
    "settings_voice",
    "filter_alt_off",
    "swap_horizontal_circle",
    "online_prediction",
    "pregnant_woman",
    "view_sidebar",
    "event_seat",
    "next_plan",
    "vertical_split",
    "camera_enhance",
    "restore_from_trash",
    "markunread_mailbox",
    "data_exploration",
    "dynamic_form",
    "calendar_view_week",
    "rocket",
    "tab",
    "accessible_forward",
    "view_stream",
    "add_to_drive",
    "remove_done",
    "request_page",
    "settings_power",
    "smart_button",
    "token",
    "card_travel",
    "outbox",
    "terminal",
    "try",
    "offline_pin",
    "calendar_view_day",
    "lock_reset",
    "rowing",
    "outlet",
    "find_replace",
    "compress",
    "chrome_reader_mode",
    "settings_brightness",
    "http",
    "assignment_returned",
    "alarm_add",
    "spellcheck",
    "backup_table",
    "play_for_work",
    "new_label",
    "credit_card_off",
    "restore_page",
    "fax",
    "gif",
    "wifi_protected_setup",
    "generating_tokens",
    "settings_overscan",
    "settings_input_composite",
    "quickreply",
    "density_medium",
    "view_day",
    "free_cancellation",
    "comment_bank",
    "outbound",
    "swap_vertical_circle",
    "cancel_schedule_send",
    "line_weight",
    "polymer",
    "add_card",
    "horizontal_split",
    "disabled_visible",
    "batch_prediction",
    "switch_access_shortcut",
    "send_and_archive",
    "picture_in_picture",
    "code_off",
    "bookmark_remove",
    "settings_bluetooth",
    "view_timeline",
    "123",
    "satellite_alt",
    "flip_to_front",
    "assured_workload",
    "shop_two",
    "event_repeat",
    "display_settings",
    "work_history",
    "gif_box",
    "speaker_notes_off",
    "webhook",
    "eject",
    "settings_input_hdmi",
    "alarm_off",
    "perm_data_setting",
    "html",
    "perm_scan_wifi",
    "hourglass_disabled",
    "settings_cell",
    "app_blocking",
    "work_off",
    "output",
    "sensors_off",
    "line_style",
    "pan_tool_alt",
    "noise_control_off",
    "switch_access_shortcut_add",
    "picture_in_picture_alt",
    "perm_device_information",
    "subtitles_off",
    "manage_history",
    "update_disabled",
    "voice_over_off",
    "settings_input_svideo",
    "app_shortcut",
    "javascript",
    "swipe_left",
    "join_full",
    "flip_to_back",
    "view_array",
    "private_connectivity",
    "open_in_new_off",
    "abc",
    "all_out",
    "label_off",
    "swipe_right",
    "rounded_corner",
    "shop_2",
    "install_desktop",
    "tab_unselected",
    "swipe_up",
    "join_inner",
    "text_rotate_vertical",
    "lightbulb_circle",
    "explore_off",
    "text_rotation_none",
    "density_small",
    "not_accessible",
    "data_thresholding",
    "pin_invoke",
    "commit",
    "troubleshoot",
    "install_mobile",
    "perm_camera_mic",
    "extension_off",
    "swipe_down",
    "view_kanban",
    "css",
    "pin_end",
    "join_left",
    "pinch",
    "swipe_vertical",
    "view_cozy",
    "text_rotate_up",
    "text_rotation_down",
    "join_right",
    "text_rotation_angledown",
    "text_rotation_angleup",
    "spatial_audio_off",
    "lock_person",
    "swipe_right_alt",
    "noise_aware",
    "php",
    "view_comfy_alt",
    "browse_gallery",
    "arrow_outward",
    "view_compact_alt",
    "swipe_down_alt",
    "network_ping",
    "density_large",
    "swipe_left_alt",
    "on_device_training",
    "swipe_up_alt",
    "spatial_tracking",
    "spatial_audio",
    "add_home",
    "unfold_more_double",
    "hls",
    "width_full",
    "hls_off",
    "width_normal",
    "face_unlock",
    "width_wide",
    "unfold_less_double",
    "repartition",
    "transcribe",
    "close",
    "close",
    "menu",
    "expand_more",
    "arrow_back",
    "chevron_right",
    "arrow_forward_ios",
    "arrow_back_ios",
    "cancel",
    "arrow_drop_down",
    "more_vert",
    "arrow_forward",
    "chevron_left",
    "check",
    "expand_less",
    "more_horiz",
    "refresh",
    "apps",
    "payments",
    "arrow_upward",
    "east",
    "campaign",
    "arrow_back_ios_new",
    "arrow_downward",
    "arrow_right",
    "menu_open",
    "fullscreen",
    "double_arrow",
    "arrow_drop_up",
    "unfold_more",
    "maps_home_work",
    "west",
    "arrow_left",
    "south",
    "expand_circle_down",
    "north",
    "north_east",
    "home_work",
    "first_page",
    "fullscreen_exit",
    "arrow_drop_down_circle",
    "last_page",
    "unfold_less",
    "subdirectory_arrow_right",
    "legend_toggle",
    "south_east",
    "app_settings_alt",
    "assistant_direction",
    "subdirectory_arrow_left",
    "north_west",
    "switch_left",
    "waterfall_chart",
    "south_west",
    "switch_right",
    "pivot_table_chart",
    "apps_outage",
    "offline_share",
    "add_home_work",
    "person",
    "person",
    "notifications",
    "groups",
    "people",
    "share",
    "person_outline",
    "school",
    "person_add",
    "public",
    "emoji_events",
    "group",
    "notifications_active",
    "engineering",
    "construction",
    "people_alt",
    "group_add",
    "psychology",
    "health_and_safety",
    "travel_explore",
    "water_drop",
    "thumb_up_alt",
    "notifications_none",
    "emoji_emotions",
    "sports_esports",
    "sentiment_satisfied",
    "location_city",
    "ios_share",
    "sentiment_very_satisfied",
    "person_add_alt",
    "precision_manufacturing",
    "emoji_objects",
    "science",
    "military_tech",
    "history_edu",
    "cake",
    "sentiment_dissatisfied",
    "workspace_premium",
    "coronavirus",
    "sentiment_very_dissatisfied",
    "emoji_people",
    "self_improvement",
    "person_remove",
    "poll",
    "handshake",
    "sports_soccer",
    "whatshot",
    "domain",
    "female",
    "mood",
    "recommend",
    "people_outline",
    "male",
    "person_off",
    "recycling",
    "person_add_alt_1",
    "sentiment_neutral",
    "masks",
    "connect_without_contact",
    "back_hand",
    "hiking",
    "architecture",
    "notifications_off",
    "waving_hand",
    "luggage",
    "thumb_down_alt",
    "front_hand",
    "mood_bad",
    "real_estate_agent",
    "emoji_nature",
    "vaccines",
    "switch_account",
    "catching_pokemon",
    "nights_stay",
    "king_bed",
    "interests",
    "sports_basketball",
    "notification_add",
    "emoji_symbols",
    "compost",
    "sports_kabaddi",
    "sports",
    "reduce_capacity",
    "emoji_food_beverage",
    "sick",
    "emoji_transportation",
    "sports_tennis",
    "man",
    "cookie",
    "transgender",
    "cruelty_free",
    "outdoor_grill",
    "elderly",
    "deck",
    "social_distance",
    "add_moderator",
    "heart_broken",
    "follow_the_signs",
    "woman",
    "plus_one",
    "clean_hands",
    "piano",
    "fireplace",
    "add_reaction",
    "sanitizer",
    "personal_injury",
    "surfing",
    "sports_motorsports",
    "pix",
    "sports_handball",
    "sports_baseball",
    "edit_notifications",
    "scale",
    "sports_volleyball",
    "pages",
    "sports_football",
    "hive",
    "diversity_3",
    "wallet",
    "downhill_skiing",
    "kayaking",
    "public_off",
    "single_bed",
    "skateboarding",
    "remove_moderator",
    "notifications_paused",
    "safety_divider",
    "group_remove",
    "person_remove_alt_1",
    "co2",
    "boy",
    "sports_cricket",
    "fitbit",
    "group_off",
    "sports_mma",
    "nordic_walking",
    "sports_golf",
    "domain_add",
    "diversity_1",
    "paragliding",
    "6_ft_apart",
    "girl",
    "sports_martial_arts",
    "snowboarding",
    "party_mode",
    "kitesurfing",
    "groups_2",
    "snowshoeing",
    "sports_hockey",
    "ice_skating",
    "sports_rugby",
    "sports_gymnastics",
    "psychology_alt",
    "thunderstorm",
    "south_america",
    "diversity_2",
    "sledding",
    "elderly_woman",
    "scoreboard",
    "groups_3",
    "no_luggage",
    "face_6",
    "face_3",
    "piano_off",
    "sign_language",
    "person_2",
    "cyclone",
    "scuba_diving",
    "face_2",
    "flood",
    "face_4",
    "tsunami",
    "roller_skating",
    "face_5",
    "person_4",
    "severe_cold",
    "tornado",
    "landslide",
    "person_3",
    "volcano",
    "18_up_rating",
    "no_adult_content",
    "assist_walker",
    "woman_2",
    "man_2",
    "blind",
    "man_4",
    "man_3",
    "add",
    "add",
    "add_circle_outline",
    "content_copy",
    "add_circle",
    "send",
    "clear",
    "mail",
    "save",
    "link",
    "remove",
    "filter_list",
    "inventory_2",
    "inventory",
    "insights",
    "remove_circle_outline",
    "sort",
    "bolt",
    "flag",
    "reply",
    "add_box",
    "remove_circle",
    "push_pin",
    "block",
    "create",
    "calculate",
    "undo",
    "how_to_reg",
    "content_paste",
    "report",
    "file_copy",
    "backspace",
    "shield",
    "archive",
    "save_alt",
    "policy",
    "tag",
    "change_circle",
    "redo",
    "content_cut",
    "forward",
    "inbox",
    "outlined_flag",
    "drafts",
    "link_off",
    "ballot",
    "biotech",
    "report_gmailerrorred",
    "stacked_bar_chart",
    "delete_sweep",
    "markunread",
    "square_foot",
    "stream",
    "add_link",
    "where_to_vote",
    "move_to_inbox",
    "copy_all",
    "dynamic_feed",
    "waves",
    "unarchive",
    "reply_all",
    "select_all",
    "content_paste_search",
    "low_priority",
    "text_format",
    "font_download",
    "weekend",
    "how_to_vote",
    "upcoming",
    "gesture",
    "save_as",
    "attribution",
    "content_paste_go",
    "flag_circle",
    "next_week",
    "filter_list_off",
    "content_paste_off",
    "report_off",
    "font_download_off",
    "deselect",
    "web_stories",
    "edit",
    "edit",
    "navigate_next",
    "photo_camera",
    "image",
    "tune",
    "picture_as_pdf",
    "receipt_long",
    "circle",
    "timer",
    "auto_stories",
    "collections",
    "navigate_before",
    "add_a_photo",
    "auto_awesome",
    "palette",
    "remove_red_eye",
    "music_note",
    "add_photo_alternate",
    "wb_sunny",
    "brush",
    "flash_on",
    "euro",
    "control_point",
    "auto_fix_high",
    "looks_one",
    "style",
    "adjust",
    "straighten",
    "photo_library",
    "camera",
    "portrait",
    "camera_alt",
    "audiotrack",
    "video_camera_front",
    "rotate_right",
    "grid_on",
    "color_lens",
    "crop_free",
    "timelapse",
    "landscape",
    "slideshow",
    "collections_bookmark",
    "crop_square",
    "currency_rupee",
    "looks_two",
    "panorama_fish_eye",
    "lens",
    "filter_vintage",
    "filter_drama",
    "compare",
    "healing",
    "auto_awesome_motion",
    "image_search",
    "looks_3",
    "rotate_left",
    "crop",
    "blur_on",
    "center_focus_strong",
    "wb_incandescent",
    "flare",
    "wb_cloudy",
    "face_retouching_natural",
    "cases",
    "colorize",
    "assistant",
    "filter_none",
    "brightness_4",
    "broken_image",
    "filter_center_focus",
    "dehaze",
    "nature_people",
    "crop_original",
    "photo",
    "auto_awesome_mosaic",
    "flash_off",
    "brightness_6",
    "tag_faces",
    "brightness_5",
    "details",
    "auto_fix_normal",
    "flip_camera_android",
    "grain",
    "flip",
    "brightness_1",
    "loupe",
    "flip_camera_ios",
    "image_not_supported",
    "movie_creation",
    "filter_1",
    "add_to_photos",
    "panorama",
    "view_comfy",
    "animation",
    "center_focus_weak",
    "movie_filter",
    "looks_4",
    "bedtime",
    "crop_din",
    "filter",
    "control_point_duplicate",
    "brightness_7",
    "leak_add",
    "nature",
    "texture",
    "photo_size_select_actual",
    "incomplete_circle",
    "video_camera_back",
    "timer_off",
    "view_compact",
    "transform",
    "currency_bitcoin",
    "motion_photos_on",
    "photo_camera_front",
    "rotate_90_degrees_ccw",
    "mic_external_on",
    "gradient",
    "looks_5",
    "assistant_photo",
    "music_off",
    "hide_image",
    "exposure_plus_1",
    "email",
    "file_download",
    "local_shipping",
    "play_arrow",
    "star",
    "warning",
    "attach_money",
    "support_agent",
    "keyboard_arrow_down",
    "light_mode",
    "storefront",
    "manage_search",
    "sensor_door"
];