import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "privacy-statement mx-auto readContainer mt-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t( 'privacyStatement.generalTitle' )), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t( 'privacyStatement.generalContent' )), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t( 'privacyStatement.cookiesTitle' )), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t( 'privacyStatement.cookiesContent' )), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t( 'privacyStatement.sslEncryptionTitle' )), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t( 'privacyStatement.sslEncryptionContent' )), 1)
    ])
  ]))
}