

import { defineComponent, onMounted, onUpdated, PropType, Ref, ref } from "vue";
import { ConfigProp } from "@/config/ConfigProp";
import { useI18n } from "vue-i18n";
import CustomColorPicker from "@/modules/editor/CustomColorPicker.vue";
import Editor from "primevue/editor";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import InputText from "primevue/inputtext";
import Icon from "@/modules/general/Icon.vue";

interface IProps {
    property?: ConfigProp,
    value?: any,
    id?: string,
    parentInformation?: any
}

enum EditMode {
    Easy,
    Advanced
}

const lodash = require( "lodash" );

export default defineComponent( {
    name: 'StringConfig',
    components: {
        CustomColorPicker,
        Editor,
        Dropdown,
        Textarea,
        InputText,
        Icon
    },
    props: {
        property: {
            type: Object as PropType<ConfigProp>
        },
        value: {},
        id: {
            type: String
        },
        parentInformation: {},
    },
    emits: [ "change" ],
    setup( props: IProps, { emit } ) {
        const currValue = ref( null );
        const i18n = useI18n();
        const mode: Ref<EditMode> = ref( EditMode.Easy );

        onMounted( () => {
            init();
        } );

        onUpdated( () => {
            if( !currValue.value && props.value ) {
                init();
            }
        } );

        function init() {
            currValue.value = lodash.cloneDeep( props.value );
            if( currValue.value == undefined ) {
                currValue.value = props.property.default;
            }
        }

        function updateConfigVal( val ) {
            currValue.value = val;
            updateConfig();
        }

        function updateConfig() {
            emit( 'change', currValue.value );
        }

        function changeMode() {
            if( mode.value === EditMode.Easy ) {
                mode.value = EditMode.Advanced;
            } else {
                mode.value = EditMode.Easy;
            }
        }

        return {
            EditMode,
            currValue,
            mode,
            updateConfigVal,
            updateConfig,
            changeMode
        };
    }
} );
