import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!
  const _component_ContainerSwitch = _resolveComponent("ContainerSwitch")!

  return (_ctx.editor)
    ? (_openBlock(), _createBlock(_component_Editor, {
        key: 0,
        config: _ctx.config
      }, null, 8, ["config"]))
    : (_openBlock(), _createBlock(_component_ContainerSwitch, {
        key: 1,
        config: _ctx.config
      }, null, 8, ["config"]))
}