import { ConfigProp } from "@/config/ConfigProp";

export class MenuConfig {
    public static get Config(): { [key: string]: ConfigProp } {
        return {
            "name": {
                "type": "string",
                "maxLength": 20
            },
            "position": {
                "type": "number",
                "format": "integer",
                "required": true
            },
            "additionalItems": {
                "type": "array",
                "newElement": { icon: {} },
                "items": {
                    "type": "object",
                    "format": "menuItem"
                }
            }
        };
    }
}