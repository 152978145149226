

import { defineComponent } from "vue";
import Gallery_1 from "@/modules/gallery/Gallery_1.vue";

export default defineComponent( {
    name: 'GallerySwitch',
    components: { Gallery_1 },
    props: {
        config: {
            type: Object
        },
        type: {
            type: Number
        }
    },
    setup() {
    }
} );
