import { ConfigProp } from "@/config/ConfigProp";

export class Partners1Config {
    public static get Config(): { [key: string]: ConfigProp } {
        return {
            "maxWidth": {
                "type": "string",
                "preDefined": [ {
                    icon: {
                        name: "fullscreen-exit", source: "bi"
                    },
                    value: "min(1600px,100%)"
                }, {
                    icon: {
                        name: "fullscreen", source: "bi"
                    },
                    value: "1600px"
                }, {
                    icon: {
                        name: "arrows-fullscreen", source: "bi"
                    },
                    value: "100%"
                }
                ]
            },
            "partners": {
                "type": "array",
                "newElement": {
                    "link": "",
                    "name": "new",
                    "image": null
                },
                "items": {
                    "type": "object",
                    "properties": {
                        "name": {
                            "type": "string",
                            "required": true,
                            "default": ""
                        },
                        "link": {
                            "type": "string",
                            "default": ""
                        },
                        "image": {
                            "type": "object",
                            "format": "image"
                        }
                    }
                }
            }
        };
    }

    public static Empty( i18n ) {
        return {
            type: "partners",
            title: {},
            style: 1,
            name: i18n.t( 'common.unnamed' ),
            config: {
                partners: [],
            }
        };
    }
}