

import { defineComponent, getCurrentInstance, onMounted, PropType, Ref, ref, watch } from "vue";
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Image from 'primevue/image';
import InputNumber from 'primevue/inputnumber';
import Fieldset from 'primevue/fieldset';
import Accordion from 'primevue/accordion';
import Checkbox from 'primevue/checkbox';
import AccordionTab from 'primevue/accordiontab';
import {
    getConfig,
} from "@/config/components/ConfigLoader";
import IconPicker from "@/modules/editor/IconPicker.vue";
import GalleryConfigurator from "@/modules/editor/GalleryConfigurator.vue";
import GalleryModal from "@/modules/editor/GalleryModal.vue";
import { nanoid } from 'nanoid';
import Editor from "primevue/editor";
import Dropdown from "primevue/dropdown";
import { moveArrayItem } from "@/utils/Utils";
import SpeedDial from "primevue/speeddial";
import { useI18n } from "vue-i18n";
import { Events1Config } from "@/config/components/events/events1";
import { Gallery1Config } from "@/config/components/gallery/gallery1";
import { Html1Config } from "@/config/components/html/html1";
import { Partners1Config } from "@/config/components/partners/partners1";
import HeaderConfigurator from "@/modules/editor/HeaderConfigurator.vue";
import { VueDraggableNext } from 'vue-draggable-next';
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { NotificationsService } from "@/services/NotificationsService";
import CustomColorPicker from "@/modules/editor/CustomColorPicker.vue";
import { ConfigProp } from "@/config/ConfigProp";
import Icon from "@/modules/general/Icon.vue";
import Textarea from "primevue/textarea";
import StringConfig from "@/modules/editor/StringConfig.vue";
import NumberConfig from "@/modules/editor/NumberConfig.vue";
import BooleanConfig from "@/modules/editor/BooleanConfig.vue";
import ObjectConfig from "@/modules/editor/ObjectConfig.vue";

const lodash = require( "lodash" );

interface IProps {
    property?: ConfigProp,
    value?: any,
    id?: string,
    parentInformation?: any
}

enum EditMode {
    Easy,
    Advanced
}
export default defineComponent( {
    name: 'ConfigProperty',
    components: {
        ObjectConfig,
        BooleanConfig,
        NumberConfig,
        StringConfig,
        Icon,
        IconPicker,
        CustomColorPicker,
        InputText,
        Button,
        Image,
        InputNumber,
        Fieldset,
        Accordion,
        AccordionTab,
        Checkbox,
        GalleryModal,
        GalleryConfigurator,
        Editor,
        Dropdown,
        SpeedDial,
        HeaderConfigurator,
        VueDraggableNext,
        TabPanel,
        TabView,
        Textarea
    },
    props: {
        property: {
            type: Object as PropType<ConfigProp>
        },
        value: {},
        id: {
            type: String
        },
        parentInformation: {},
    },
    emits: [ "change" ],
    setup( props: IProps, { emit } ) {
        const app = getCurrentInstance();
        const currValue = ref( null );
        const open: Ref<number> = ref( -1 );
        const reload: Ref<number> = ref( 0 );
        const loaded: Ref<boolean> = ref( false );
        const mode: Ref<EditMode> = ref( EditMode.Easy );
        const rndId = ref( "id" + nanoid() );
        const i18n = useI18n();
        const activeIndex = ref( props.property.format === "tabs" ? 0 : -1 );

        onMounted( () => {
            currValue.value = lodash.cloneDeep( props.value );
            if( currValue.value == undefined ) {
                currValue.value = props.property.default;
            }

            if( props.property.type == "array" && props.property.items.format == "page" ) {
                watch( app.appContext.config.globalProperties.editorPage, ( newValue, oldValue ) => {
                    let page = app.appContext.config.globalProperties.editorPage.value;
                    if( currValue.value ) {
                        const newIndex = currValue.value.findIndex( p => p.path == page || page == '/' + p.path );
                        if( newIndex >= 0 )
                            activeIndex.value = newIndex;
                    }
                } );
            }

            if( props.property.format == "module" && props.property.type == "array" ) {
                watch( app.appContext.config.globalProperties.openModalEditor, ( newValue, oldValue ) => {
                    let index = app.appContext.config.globalProperties.openModalEditor.value;
                    if( index >= 0 ) {
                        activeIndex.value = index;
                        setTimeout( () => {
                            location.href = '#';
                            location.href = ( '#' + rndId.value.toString() + index.toString() );
                        }, 1000 ); // wait till accordion is opened
                    }
                } );
            }

            loaded.value = true;
        } );

        function getName( item ) {
            if( item && item.name ) {
                if( item.type ) {
                    return i18n.t( 'enum.' + item.type ) + ": " + item.name;
                } else {
                    return item.name;
                }
            }
            return i18n.t( 'common.unnamed' );
        }



        function getConfigByObject( prop: ConfigProp, item ): ConfigProp {
            let conf = getConfig( prop.format );
            if( conf === null ) {
                conf = prop.properties;
            }

            return {
                default: prop.default,
                format: prop.format,
                hidden: prop.hidden,
                items: prop.items,
                maxLength: prop.maxLength,
                properties: conf,
                required: prop.required,
                type: prop.type
            };
        }

        let timeOut = null;

        function updateConfig( index = null, value = null, time = 0 ) {
            if( props.property.regex && currValue.value ) {
                let regex = new RegExp( props.property.regex, 'g' );
                if( currValue.value.match( regex ) === null ) {
                    NotificationsService.error( i18n.t( 'notification.error' ), i18n.t( 'notification.errors.regexInvalidMatch' ) );
                    return;
                }
            }
            if( index != undefined ) {
                currValue.value[index] = value;
                change();
            } else {
                if( timeOut )
                    clearTimeout( timeOut );
                if( value ) {
                    currValue.value = value;
                }
                timeOut = setTimeout( change, time );
            }
        }

        function change() {
            emit( 'change', currValue.value );
        }

        function arrayItemToPosition( oldIndex: number, newIndex: number ) {
            currValue.value = moveArrayItem( currValue.value, oldIndex, newIndex );
            change();
            reload.value++;
        }

        function copyItem( index: number ) {
            currValue.value.splice( index + 1, 0, currValue.value[index] );
            change();
            reload.value++;
        }

        function addItem( index: number ) {
            currValue.value.splice( index + 1, 0, props.property.newElement );
            change();
            activeIndex.value = index + 1;
            reload.value++;
        }

        function deleteItem( index: number ) {
            currValue.value.splice( index, 1 );
            change();
            reload.value++;
        }

        function getModules( index: number ) {
            return [
                {
                    label: i18n.t( 'enum.events' ),
                    icon: 'bi bi-calendar-event',
                    command: ( event ) => {
                        insertModal( index + 1, event, Events1Config.Empty( i18n ) );
                    }
                }, {
                    label: i18n.t( 'enum.gallery' ),
                    icon: 'bi bi-images',
                    command: ( event ) => {
                        insertModal( index + 1, event, Gallery1Config.Empty( i18n ) );
                    }
                }, {
                    label: i18n.t( 'enum.html' ),
                    icon: 'bi bi-filetype-html',
                    command: ( event ) => {
                        insertModal( index + 1, event, Html1Config.Empty( i18n ) );
                    }
                }, {
                    label: i18n.t( 'enum.partners' ),
                    icon: 'bi bi-person-vcard',
                    command: ( event ) => {
                        insertModal( index + 1, event, Partners1Config.Empty( i18n ) );
                    }
                },
            ];
        }

        function insertModal( index, event, modal ) {
            open.value = -1;
            currValue.value.splice( index, 0, modal );
            event.originalEvent.stopPropagation();
            activeIndex.value = index;
            change();
            reload.value++;
        }

        function openSpeedDial( index: number ) {
            if( open.value >= 0 ) {
                open.value = -1;
            } else {
                open.value = index;
            }
        }

        function addItemIfLast( event ) {
            if( event.index === props.value.length ) {
                addItem( event.index - 1 );
            }
        }

        function changeMode() {
            if( mode.value === EditMode.Easy ) {
                mode.value = EditMode.Advanced;
            } else {
                mode.value = EditMode.Easy;
            }
        }

        return {
            EditMode,
            mode,
            rndId,
            currValue,
            activeIndex,
            open,
            loaded,
            reload,
            openSpeedDial,
            getName,
            getConfigByObject,
            updateConfig,
            arrayItemToPosition,
            copyItem,
            addItem,
            deleteItem,
            getModules,
            addItemIfLast,
            changeMode
        };
    }
} );
