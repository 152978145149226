

import { defineComponent, onMounted, Ref, ref } from "vue";


export default defineComponent( {
    name: 'Gallery_1',
    components: {},
    props: {
        config: {
            type: Object
        },
        id: {
            type: String
        }
    },
    setup(props: any) {
        const currImg: Ref<number> = ref( 0 );
        const skewY: Ref<string> = ref( "skewY(0)" );

        onMounted( () => {
            resetInterval();
        } );

        let interval = null;

        function resetInterval() {
            if( props.config.autoSwitchTimer <= 0 ) {
                return;
            }
            const maxImgs = props.config.images.length;
            if( interval ) {
                clearInterval( interval );
            }
            if( maxImgs > 1 ) {
                interval = setInterval( () => {
                    currImg.value = ( currImg.value + 1 ) % maxImgs;
                }, props.config.autoSwitchTimer * 1000 );
            }
        }

        function setImg( i: number ) {
            const maxImgs = props.config.images.length;
            currImg.value = ( i + maxImgs ) % maxImgs;
            resetInterval();
        }

        let startX = 0;
        let startY = 0;
        let lastTouch: Touch = null;

        function handleTouchStart( event: TouchEvent ) {
            const touch = event.touches[0];
            startX = touch.clientX;
            startY = touch.clientY;
        }

        function handleTouchMove( event: TouchEvent ) {
            lastTouch = event.touches[0];
            const dist = startX - lastTouch.clientX;
            const distX = Math.abs( startX - lastTouch.clientX );
            const distY = Math.abs( startY - lastTouch.clientY );
            if( distY < distX ) {
                event.preventDefault();
                let turn = Math.max( Math.min( dist / 300, 1 ), -1 ) / -30;
                skewY.value = "skewY(" + turn.toString() + "turn)";
            }
        }

        function handleTouchEnd() {
            const distX = Math.abs( startX - lastTouch.clientX );
            if( distX > 50 && props.config.manual ) { // minimum distance for a swipe
                if( distX > Math.abs( startY - lastTouch.clientY ) ) {
                    if( startX > lastTouch.clientX ) {
                        setImg( currImg.value + 1 );
                    } else {
                        setImg( currImg.value - 1 );
                    }
                }
            }
            startX = 0;
            startY = 0;
            lastTouch = null;
            skewY.value = "skewY(0)";
        }

        return {
            currImg,
            skewY,
            setImg,
            handleTouchStart,
            handleTouchEnd,
            handleTouchMove,
        };
    }
} );
