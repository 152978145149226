import { IconConfig } from "@/config/components/icon";
import { ModuleConfig } from "@/config/components/module";
import { MenuItemConfig } from "@/config/components/menuItem";
import { MenuConfig } from "@/config/components/menu";
import { Gallery1Config } from "@/config/components/gallery/gallery1";
import { Html1Config } from "@/config/components/html/html1";
import { Events1Config } from "@/config/components/events/events1";
import { Partners1Config } from "@/config/components/partners/partners1";
import { ConfigProp } from "@/config/ConfigProp";
import { ContainerConfig } from "@/config/components/container";
import { Container1Config } from "@/config/components/container/container1";

export function getMaxModalNumber(): number {
    return 1;
}

export function getConfig( type ): { [key: string]: ConfigProp } {
    switch( type ) {
        case "icon":
            return IconConfig.Config;
        case "module":
            return ModuleConfig.Config;
        case "menuItem":
            return MenuItemConfig.Config;
        case "menu":
            return MenuConfig.Config;
        case "container":
            return ContainerConfig.Config;
    }
    return null;
}

export function getModuleConfig( type: string, style: number ) {
    switch( type ) {
        case "gallery":
            return getGalleryConfig( style );
        case "html":
            return getHtmlConfig( style );
        case "events":
            return getEventsConfig( style );
        case "partners":
            return getPartnersConfig( style );
    }
    return null;
}

export function getContainerConfig( style: number ) {
    switch( style ) {
        case 1:
            return Container1Config.Config;
    }
    return null;
}

export function getMaxStyleNumber( type: string ) {
    switch( type ) {
        case "gallery":
            return 1;
        case "html":
            return 1;
        case "events":
            return 1;
        case "partners":
            return 1;
        case "container":
            return 1;
    }
    return null;
}

function getGalleryConfig( style: number ) {
    switch( style ) {
        case 1:
            return Gallery1Config.Config;
    }
    return null;
}

function getHtmlConfig( style: number ) {
    switch( style ) {
        case 1:
            return Html1Config.Config;
    }
    return null;
}

function getEventsConfig( style: number ) {
    switch( style ) {
        case 1:
            return Events1Config.Config;
    }
    return null;
}

function getPartnersConfig( style: number ) {
    switch( style ) {
        case 1:
            return Partners1Config.Config;
    }
    return null;
}