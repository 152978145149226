

import { defineComponent, onMounted, PropType, ref, Ref } from "vue";
import { IMenuItem } from "@/utils/interfaces/IMenuItem";
import HMenu from "@/modules/general/HMenu.vue";

export default defineComponent( {
    name: 'Menu_2',
    components: { HMenu },
    props: {
        additionalItems: {
            type: Array as PropType<IMenuItem[]>,
            default: () => []
        }
    },
    setup() {
        const container: Ref<HTMLElement> = ref( null );

        onMounted( () => {
            container.value = document.getElementById( "mainMenu" );
        } );

        return {
            container
        };
    }
} );
