

import { defineComponent } from "vue";
import Menu_2 from "@/modules/container/1/Menu_2.vue";
import Menu_1 from "@/modules/container/1/Menu_1.vue";
import { getSiteTypeByPath, SiteType } from "@/utils/SiteType";
import LegalNotice from "@/modules/legal/LegalNotice.vue";
import PrivacyStatement from "@/modules/legal/PrivacyStatement.vue";
import ModuleContainer from "@/modules/container/ModuleContainer.vue";

export default defineComponent( {
    name: 'Container_1',
    components: {
        ModuleContainer,
        PrivacyStatement,
        LegalNotice,
        Menu_1,
        Menu_2
    },
    props: {
        config: {
            type: Object
        }
    },
    setup() {
        const siteType = getSiteTypeByPath();

        return {
            SiteType,
            siteType,
        }
    }
} );
