import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "position-absolute",
  style: {"z-index":"1001"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      type: "text",
      class: _normalizeClass(["p-colorpicker-preview p-inputtext p-input-color cursor-pointer w-100", _ctx.value ? '' : 'p-no-color']),
      readonly: "",
      style: _normalizeStyle({ 'background-color': _ctx.currValue}),
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["stop"]))
    }, null, 6),
    (_ctx.show)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ColorPicker, {
            color: _ctx.currValue,
            onChangeColor: _ctx.update,
            class: "no-alpha"
          }, null, 8, ["color", "onChangeColor"])
        ])), [
          [_directive_click_outside, _ctx.hide]
        ])
      : _createCommentVNode("", true)
  ]))
}