import { IMenuItem } from "@/utils/interfaces/IMenuItem";

export function adjustMenuToWidth( allMenuItems: IMenuItem[], parentElement: HTMLElement ): { first: IMenuItem[], second: IMenuItem[] } {
    let last = -1;
    const menuItems = allMenuItems.filter( mi => mi.display != false );
    if( menuItems.length < 2 || !parentElement ) {
        return { first: menuItems, second: [] };
    }

    const firstElement = parentElement.children[0] as HTMLElement;
    for( let i = 1; i < menuItems.length; i++ ) {
        if( ( parentElement.children[i] as HTMLElement ).offsetTop != firstElement.offsetTop ) {
            last = i - 1;
            break;
        }
    }

    if( last == -1 ) {
        return { first: menuItems, second: [] };
    }
    if( last == 0 ) {
        return { first: [], second: menuItems };
    }

    const p1 = menuItems.slice( 0, last );
    const p2 = menuItems.slice( last );
    return { first: p1, second: p2 };
}