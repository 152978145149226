import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "position-relative"
}
const _hoisted_2 = { class: "container-top-end" }
const _hoisted_3 = { class: "m-0 px-3 pt-3 pb-1" }
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_GallerySwitch = _resolveComponent("GallerySwitch")!
  const _component_HTMLSwitch = _resolveComponent("HTMLSwitch")!
  const _component_EventsSwitch = _resolveComponent("EventsSwitch")!
  const _component_PartnersSwitch = _resolveComponent("PartnersSwitch")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modules, (module, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: module }, [
      (_ctx.editor)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Button, {
                icon: "bi bi-pencil",
                class: "p-button-text bg-white border text-black",
                onClick: ($event: any) => (_ctx.edit(index))
              }, null, 8, ["onClick"])
            ])
          ]))
        : _createCommentVNode("", true),
      (module.title && module.title.text)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(module.title.class),
            style: _normalizeStyle({backgroundColor: module.config.bgColor})
          }, [
            _createElementVNode("div", {
              class: "mx-auto",
              style: _normalizeStyle({maxWidth: module.config.maxWidth})
            }, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(module.title.text), 1)
            ], 4)
          ], 6))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        class: "anchor",
        style: _normalizeStyle({top: -_ctx.offsetTop + 'px'}),
        id: module.type + index
      }, null, 12, _hoisted_4),
      (module.type === 'gallery')
        ? (_openBlock(), _createBlock(_component_GallerySwitch, {
            key: 2,
            config: module.config,
            id: module.type + index  + 'Module',
            type: module.style
          }, null, 8, ["config", "id", "type"]))
        : _createCommentVNode("", true),
      (module.type === 'html')
        ? (_openBlock(), _createBlock(_component_HTMLSwitch, {
            key: 3,
            config: module.config,
            id: module.type + index  + 'Module',
            type: module.style
          }, null, 8, ["config", "id", "type"]))
        : _createCommentVNode("", true),
      (module.type === 'events')
        ? (_openBlock(), _createBlock(_component_EventsSwitch, {
            key: 4,
            config: module.config,
            id: module.type + index  + 'Module',
            type: module.style
          }, null, 8, ["config", "id", "type"]))
        : _createCommentVNode("", true),
      (module.type === 'partners')
        ? (_openBlock(), _createBlock(_component_PartnersSwitch, {
            key: 5,
            config: module.config,
            id: module.type + index  + 'Module',
            type: module.style
          }, null, 8, ["config", "id", "type"]))
        : _createCommentVNode("", true)
    ], 64))
  }), 128))
}