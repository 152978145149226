import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.editor)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.isExternalLink(_ctx.item.path))
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.item.path,
              target: "_blank",
              class: _normalizeClass(_ctx.linkClass)
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 10, _hoisted_1))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setSite(_ctx.item.path))),
              class: _normalizeClass([_ctx.linkClass, "cursor-pointer"])
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 2))
      ], 64))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: _ctx.item.path,
        class: _normalizeClass(_ctx.linkClass)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 10, _hoisted_2))
}