import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HMenu = _resolveComponent("HMenu")!

  return (_ctx.container)
    ? (_openBlock(), _createBlock(_component_HMenu, {
        key: 0,
        container: _ctx.container,
        "menu-items": _ctx.additionalItems
      }, null, 8, ["container", "menu-items"]))
    : _createCommentVNode("", true)
}