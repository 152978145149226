export const biIcons = [
    "1-circle",
    "1-circle-fill",
    "1-square",
    "1-square-fill",
    "123",
    "2-circle",
    "2-circle-fill",
    "2-square",
    "2-square-fill",
    "3-circle",
    "3-circle-fill",
    "3-square",
    "3-square-fill",
    "4-circle",
    "4-circle-fill",
    "4-square",
    "4-square-fill",
    "5-circle",
    "5-circle-fill",
    "5-square",
    "5-square-fill",
    "6-circle",
    "6-circle-fill",
    "6-square",
    "6-square-fill",
    "7-circle",
    "7-circle-fill",
    "7-square",
    "7-square-fill",
    "8-circle",
    "8-circle-fill",
    "8-square",
    "8-square-fill",
    "9-circle",
    "9-circle-fill",
    "9-square",
    "9-square-fill",
    "activity",
    "airplane",
    "airplane-engines",
    "airplane-engines-fill",
    "airplane-fill",
    "alarm",
    "alarm-fill",
    "alexa",
    "align-bottom",
    "align-center",
    "align-end",
    "align-middle",
    "align-start",
    "align-top",
    "alipay",
    "alt",
    "android",
    "android2",
    "app",
    "app-indicator",
    "apple",
    "archive",
    "archive-fill",
    "arrow-90deg-down",
    "arrow-90deg-left",
    "arrow-90deg-right",
    "arrow-90deg-up",
    "arrow-bar-down",
    "arrow-bar-left",
    "arrow-bar-right",
    "arrow-bar-up",
    "arrow-clockwise",
    "arrow-counterclockwise",
    "arrow-down",
    "arrow-down-circle",
    "arrow-down-circle-fill",
    "arrow-down-left-circle",
    "arrow-down-left-circle-fill",
    "arrow-down-left-square",
    "arrow-down-left-square-fill",
    "arrow-down-right-circle",
    "arrow-down-right-circle-fill",
    "arrow-down-right-square",
    "arrow-down-right-square-fill",
    "arrow-down-square",
    "arrow-down-square-fill",
    "arrow-down-left",
    "arrow-down-right",
    "arrow-down-short",
    "arrow-down-up",
    "arrow-left",
    "arrow-left-circle",
    "arrow-left-circle-fill",
    "arrow-left-square",
    "arrow-left-square-fill",
    "arrow-left-right",
    "arrow-left-short",
    "arrow-repeat",
    "arrow-return-left",
    "arrow-return-right",
    "arrow-right",
    "arrow-right-circle",
    "arrow-right-circle-fill",
    "arrow-right-square",
    "arrow-right-square-fill",
    "arrow-right-short",
    "arrow-through-heart",
    "arrow-through-heart-fill",
    "arrow-up",
    "arrow-up-circle",
    "arrow-up-circle-fill",
    "arrow-up-left-circle",
    "arrow-up-left-circle-fill",
    "arrow-up-left-square",
    "arrow-up-left-square-fill",
    "arrow-up-right-circle",
    "arrow-up-right-circle-fill",
    "arrow-up-right-square",
    "arrow-up-right-square-fill",
    "arrow-up-square",
    "arrow-up-square-fill",
    "arrow-up-left",
    "arrow-up-right",
    "arrow-up-short",
    "arrows-angle-contract",
    "arrows-angle-expand",
    "arrows-collapse",
    "arrows-expand",
    "arrows-fullscreen",
    "arrows-move",
    "aspect-ratio",
    "aspect-ratio-fill",
    "asterisk",
    "at",
    "award",
    "award-fill",
    "back",
    "backspace",
    "backspace-fill",
    "backspace-reverse",
    "backspace-reverse-fill",
    "badge-3d",
    "badge-3d-fill",
    "badge-4k",
    "badge-4k-fill",
    "badge-8k",
    "badge-8k-fill",
    "badge-ad",
    "badge-ad-fill",
    "badge-ar",
    "badge-ar-fill",
    "badge-cc",
    "badge-cc-fill",
    "badge-hd",
    "badge-hd-fill",
    "badge-sd",
    "badge-sd-fill",
    "badge-tm",
    "badge-tm-fill",
    "badge-vo",
    "badge-vo-fill",
    "badge-vr",
    "badge-vr-fill",
    "badge-wc",
    "badge-wc-fill",
    "bag",
    "bag-check",
    "bag-check-fill",
    "bag-dash",
    "bag-dash-fill",
    "bag-fill",
    "bag-heart",
    "bag-heart-fill",
    "bag-plus",
    "bag-plus-fill",
    "bag-x",
    "bag-x-fill",
    "balloon",
    "balloon-fill",
    "balloon-heart",
    "balloon-heart-fill",
    "bandaid",
    "bandaid-fill",
    "bank",
    "bank2",
    "bar-chart",
    "bar-chart-fill",
    "bar-chart-line",
    "bar-chart-line-fill",
    "bar-chart-steps",
    "basket",
    "basket-fill",
    "basket2",
    "basket2-fill",
    "basket3",
    "basket3-fill",
    "battery",
    "battery-charging",
    "battery-full",
    "battery-half",
    "behance",
    "bell",
    "bell-fill",
    "bell-slash",
    "bell-slash-fill",
    "bezier",
    "bezier2",
    "bicycle",
    "binoculars",
    "binoculars-fill",
    "blockquote-left",
    "blockquote-right",
    "bluetooth",
    "body-text",
    "book",
    "book-fill",
    "book-half",
    "bookmark",
    "bookmark-check",
    "bookmark-check-fill",
    "bookmark-dash",
    "bookmark-dash-fill",
    "bookmark-fill",
    "bookmark-heart",
    "bookmark-heart-fill",
    "bookmark-plus",
    "bookmark-plus-fill",
    "bookmark-star",
    "bookmark-star-fill",
    "bookmark-x",
    "bookmark-x-fill",
    "bookmarks",
    "bookmarks-fill",
    "bookshelf",
    "boombox",
    "boombox-fill",
    "bootstrap",
    "bootstrap-fill",
    "bootstrap-reboot",
    "border",
    "border-all",
    "border-bottom",
    "border-center",
    "border-inner",
    "border-left",
    "border-middle",
    "border-outer",
    "border-right",
    "border-style",
    "border-top",
    "border-width",
    "bounding-box",
    "bounding-box-circles",
    "box",
    "box-arrow-down-left",
    "box-arrow-down-right",
    "box-arrow-down",
    "box-arrow-in-down",
    "box-arrow-in-down-left",
    "box-arrow-in-down-right",
    "box-arrow-in-left",
    "box-arrow-in-right",
    "box-arrow-in-up",
    "box-arrow-in-up-left",
    "box-arrow-in-up-right",
    "box-arrow-left",
    "box-arrow-right",
    "box-arrow-up",
    "box-arrow-up-left",
    "box-arrow-up-right",
    "box-fill",
    "box-seam",
    "box-seam-fill",
    "box2",
    "box2-fill",
    "box2-heart",
    "box2-heart-fill",
    "boxes",
    "braces",
    "braces-asterisk",
    "bricks",
    "briefcase",
    "briefcase-fill",
    "brightness-alt-high",
    "brightness-alt-high-fill",
    "brightness-alt-low",
    "brightness-alt-low-fill",
    "brightness-high",
    "brightness-high-fill",
    "brightness-low",
    "brightness-low-fill",
    "broadcast",
    "broadcast-pin",
    "browser-chrome",
    "browser-edge",
    "browser-firefox",
    "browser-safari",
    "brush",
    "brush-fill",
    "bucket",
    "bucket-fill",
    "bug",
    "bug-fill",
    "building",
    "bullseye",
    "c-circle",
    "c-circle-fill",
    "c-square",
    "c-square-fill",
    "calculator",
    "calculator-fill",
    "calendar",
    "calendar-check",
    "calendar-check-fill",
    "calendar-date",
    "calendar-date-fill",
    "calendar-day",
    "calendar-day-fill",
    "calendar-event",
    "calendar-event-fill",
    "calendar-fill",
    "calendar-heart",
    "calendar-heart-fill",
    "calendar-minus",
    "calendar-minus-fill",
    "calendar-month",
    "calendar-month-fill",
    "calendar-plus",
    "calendar-plus-fill",
    "calendar-range",
    "calendar-range-fill",
    "calendar-week",
    "calendar-week-fill",
    "calendar-x",
    "calendar-x-fill",
    "calendar2",
    "calendar2-check",
    "calendar2-check-fill",
    "calendar2-date",
    "calendar2-date-fill",
    "calendar2-day",
    "calendar2-day-fill",
    "calendar2-event",
    "calendar2-event-fill",
    "calendar2-fill",
    "calendar2-heart",
    "calendar2-heart-fill",
    "calendar2-minus",
    "calendar2-minus-fill",
    "calendar2-month",
    "calendar2-month-fill",
    "calendar2-plus",
    "calendar2-plus-fill",
    "calendar2-range",
    "calendar2-range-fill",
    "calendar2-week",
    "calendar2-week-fill",
    "calendar2-x",
    "calendar2-x-fill",
    "calendar3",
    "calendar3-event",
    "calendar3-event-fill",
    "calendar3-fill",
    "calendar3-range",
    "calendar3-range-fill",
    "calendar3-week",
    "calendar3-week-fill",
    "calendar4",
    "calendar4-event",
    "calendar4-range",
    "calendar4-week",
    "camera",
    "camera2",
    "camera-fill",
    "camera-reels",
    "camera-reels-fill",
    "camera-video",
    "camera-video-fill",
    "camera-video-off",
    "camera-video-off-fill",
    "capslock",
    "capslock-fill",
    "capsule",
    "capsule-pill",
    "car-front",
    "car-front-fill",
    "card-checklist",
    "card-heading",
    "card-image",
    "card-list",
    "card-text",
    "caret-down",
    "caret-down-fill",
    "caret-down-square",
    "caret-down-square-fill",
    "caret-left",
    "caret-left-fill",
    "caret-left-square",
    "caret-left-square-fill",
    "caret-right",
    "caret-right-fill",
    "caret-right-square",
    "caret-right-square-fill",
    "caret-up",
    "caret-up-fill",
    "caret-up-square",
    "caret-up-square-fill",
    "cart",
    "cart-check",
    "cart-check-fill",
    "cart-dash",
    "cart-dash-fill",
    "cart-fill",
    "cart-plus",
    "cart-plus-fill",
    "cart-x",
    "cart-x-fill",
    "cart2",
    "cart3",
    "cart4",
    "cash",
    "cash-coin",
    "cash-stack",
    "cassette",
    "cassette-fill",
    "cast",
    "cc-circle",
    "cc-circle-fill",
    "cc-square",
    "cc-square-fill",
    "chat",
    "chat-dots",
    "chat-dots-fill",
    "chat-fill",
    "chat-heart",
    "chat-heart-fill",
    "chat-left",
    "chat-left-dots",
    "chat-left-dots-fill",
    "chat-left-fill",
    "chat-left-heart",
    "chat-left-heart-fill",
    "chat-left-quote",
    "chat-left-quote-fill",
    "chat-left-text",
    "chat-left-text-fill",
    "chat-quote",
    "chat-quote-fill",
    "chat-right",
    "chat-right-dots",
    "chat-right-dots-fill",
    "chat-right-fill",
    "chat-right-heart",
    "chat-right-heart-fill",
    "chat-right-quote",
    "chat-right-quote-fill",
    "chat-right-text",
    "chat-right-text-fill",
    "chat-square",
    "chat-square-dots",
    "chat-square-dots-fill",
    "chat-square-fill",
    "chat-square-heart",
    "chat-square-heart-fill",
    "chat-square-quote",
    "chat-square-quote-fill",
    "chat-square-text",
    "chat-square-text-fill",
    "chat-text",
    "chat-text-fill",
    "check",
    "check-all",
    "check-circle",
    "check-circle-fill",
    "check-lg",
    "check-square",
    "check-square-fill",
    "check2",
    "check2-all",
    "check2-circle",
    "check2-square",
    "chevron-bar-contract",
    "chevron-bar-down",
    "chevron-bar-expand",
    "chevron-bar-left",
    "chevron-bar-right",
    "chevron-bar-up",
    "chevron-compact-down",
    "chevron-compact-left",
    "chevron-compact-right",
    "chevron-compact-up",
    "chevron-contract",
    "chevron-double-down",
    "chevron-double-left",
    "chevron-double-right",
    "chevron-double-up",
    "chevron-down",
    "chevron-expand",
    "chevron-left",
    "chevron-right",
    "chevron-up",
    "circle",
    "circle-fill",
    "circle-half",
    "slash-circle",
    "circle-square",
    "clipboard",
    "clipboard-check",
    "clipboard-check-fill",
    "clipboard-data",
    "clipboard-data-fill",
    "clipboard-fill",
    "clipboard-heart",
    "clipboard-heart-fill",
    "clipboard-minus",
    "clipboard-minus-fill",
    "clipboard-plus",
    "clipboard-plus-fill",
    "clipboard-pulse",
    "clipboard-x",
    "clipboard-x-fill",
    "clipboard2",
    "clipboard2-check",
    "clipboard2-check-fill",
    "clipboard2-data",
    "clipboard2-data-fill",
    "clipboard2-fill",
    "clipboard2-heart",
    "clipboard2-heart-fill",
    "clipboard2-minus",
    "clipboard2-minus-fill",
    "clipboard2-plus",
    "clipboard2-plus-fill",
    "clipboard2-pulse",
    "clipboard2-pulse-fill",
    "clipboard2-x",
    "clipboard2-x-fill",
    "clock",
    "clock-fill",
    "clock-history",
    "cloud",
    "cloud-arrow-down",
    "cloud-arrow-down-fill",
    "cloud-arrow-up",
    "cloud-arrow-up-fill",
    "cloud-check",
    "cloud-check-fill",
    "cloud-download",
    "cloud-download-fill",
    "cloud-drizzle",
    "cloud-drizzle-fill",
    "cloud-fill",
    "cloud-fog",
    "cloud-fog-fill",
    "cloud-fog2",
    "cloud-fog2-fill",
    "cloud-hail",
    "cloud-hail-fill",
    "cloud-haze",
    "cloud-haze-fill",
    "cloud-haze2",
    "cloud-haze2-fill",
    "cloud-lightning",
    "cloud-lightning-fill",
    "cloud-lightning-rain",
    "cloud-lightning-rain-fill",
    "cloud-minus",
    "cloud-minus-fill",
    "cloud-moon",
    "cloud-moon-fill",
    "cloud-plus",
    "cloud-plus-fill",
    "cloud-rain",
    "cloud-rain-fill",
    "cloud-rain-heavy",
    "cloud-rain-heavy-fill",
    "cloud-slash",
    "cloud-slash-fill",
    "cloud-sleet",
    "cloud-sleet-fill",
    "cloud-snow",
    "cloud-snow-fill",
    "cloud-sun",
    "cloud-sun-fill",
    "cloud-upload",
    "cloud-upload-fill",
    "clouds",
    "clouds-fill",
    "cloudy",
    "cloudy-fill",
    "code",
    "code-slash",
    "code-square",
    "coin",
    "collection",
    "collection-fill",
    "collection-play",
    "collection-play-fill",
    "columns",
    "columns-gap",
    "command",
    "compass",
    "compass-fill",
    "cone",
    "cone-striped",
    "controller",
    "cpu",
    "cpu-fill",
    "credit-card",
    "credit-card-2-back",
    "credit-card-2-back-fill",
    "credit-card-2-front",
    "credit-card-2-front-fill",
    "credit-card-fill",
    "crop",
    "cup",
    "cup-fill",
    "cup-hot",
    "cup-hot-fill",
    "cup-straw",
    "currency-bitcoin",
    "currency-dollar",
    "currency-euro",
    "currency-exchange",
    "currency-pound",
    "currency-rupee",
    "currency-yen",
    "cursor",
    "cursor-fill",
    "cursor-text",
    "dash",
    "dash-circle",
    "dash-circle-dotted",
    "dash-circle-fill",
    "dash-lg",
    "dash-square",
    "dash-square-dotted",
    "dash-square-fill",
    "device-hdd",
    "device-hdd-fill",
    "device-ssd",
    "device-ssd-fill",
    "diagram-2",
    "diagram-2-fill",
    "diagram-3",
    "diagram-3-fill",
    "diamond",
    "diamond-fill",
    "diamond-half",
    "dice-1",
    "dice-1-fill",
    "dice-2",
    "dice-2-fill",
    "dice-3",
    "dice-3-fill",
    "dice-4",
    "dice-4-fill",
    "dice-5",
    "dice-5-fill",
    "dice-6",
    "dice-6-fill",
    "disc",
    "disc-fill",
    "discord",
    "display",
    "display-fill",
    "displayport",
    "displayport-fill",
    "distribute-horizontal",
    "distribute-vertical",
    "door-closed",
    "door-closed-fill",
    "door-open",
    "door-open-fill",
    "dot",
    "download",
    "dpad",
    "dpad-fill",
    "dribbble",
    "dropbox",
    "droplet",
    "droplet-fill",
    "droplet-half",
    "ear",
    "ear-fill",
    "earbuds",
    "easel",
    "easel-fill",
    "easel2",
    "easel2-fill",
    "easel3",
    "easel3-fill",
    "egg",
    "egg-fill",
    "egg-fried",
    "eject",
    "eject-fill",
    "emoji-angry",
    "emoji-angry-fill",
    "emoji-dizzy",
    "emoji-dizzy-fill",
    "emoji-expressionless",
    "emoji-expressionless-fill",
    "emoji-frown",
    "emoji-frown-fill",
    "emoji-heart-eyes",
    "emoji-heart-eyes-fill",
    "emoji-kiss",
    "emoji-kiss-fill",
    "emoji-laughing",
    "emoji-laughing-fill",
    "emoji-neutral",
    "emoji-neutral-fill",
    "emoji-smile",
    "emoji-smile-fill",
    "emoji-smile-upside-down",
    "emoji-smile-upside-down-fill",
    "emoji-sunglasses",
    "emoji-sunglasses-fill",
    "emoji-wink",
    "emoji-wink-fill",
    "envelope",
    "envelope-check",
    "envelope-check-fill",
    "envelope-dash",
    "envelope-dash-fill",
    "envelope-exclamation",
    "envelope-exclamation-fill",
    "envelope-fill",
    "envelope-heart",
    "envelope-heart-fill",
    "envelope-open",
    "envelope-open-fill",
    "envelope-open-heart",
    "envelope-open-heart-fill",
    "envelope-paper",
    "envelope-paper-fill",
    "envelope-paper-heart",
    "envelope-paper-heart-fill",
    "envelope-plus",
    "envelope-plus-fill",
    "envelope-slash",
    "envelope-slash-fill",
    "envelope-x",
    "envelope-x-fill",
    "eraser",
    "eraser-fill",
    "escape",
    "ethernet",
    "ev-station",
    "ev-station-fill",
    "exclamation",
    "exclamation-circle",
    "exclamation-circle-fill",
    "exclamation-diamond",
    "exclamation-diamond-fill",
    "exclamation-lg",
    "exclamation-octagon",
    "exclamation-octagon-fill",
    "exclamation-square",
    "exclamation-square-fill",
    "exclamation-triangle",
    "exclamation-triangle-fill",
    "exclude",
    "explicit",
    "explicit-fill",
    "eye",
    "eye-fill",
    "eye-slash",
    "eye-slash-fill",
    "eyedropper",
    "eyeglasses",
    "facebook",
    "fan",
    "fast-forward",
    "fast-forward-btn",
    "fast-forward-btn-fill",
    "fast-forward-circle",
    "fast-forward-circle-fill",
    "fast-forward-fill",
    "file",
    "file-arrow-down",
    "file-arrow-down-fill",
    "file-arrow-up",
    "file-arrow-up-fill",
    "file-bar-graph",
    "file-bar-graph-fill",
    "file-binary",
    "file-binary-fill",
    "file-break",
    "file-break-fill",
    "file-check",
    "file-check-fill",
    "file-code",
    "file-code-fill",
    "file-diff",
    "file-diff-fill",
    "file-earmark",
    "file-earmark-arrow-down",
    "file-earmark-arrow-down-fill",
    "file-earmark-arrow-up",
    "file-earmark-arrow-up-fill",
    "file-earmark-bar-graph",
    "file-earmark-bar-graph-fill",
    "file-earmark-binary",
    "file-earmark-binary-fill",
    "file-earmark-break",
    "file-earmark-break-fill",
    "file-earmark-check",
    "file-earmark-check-fill",
    "file-earmark-code",
    "file-earmark-code-fill",
    "file-earmark-diff",
    "file-earmark-diff-fill",
    "file-earmark-easel",
    "file-earmark-easel-fill",
    "file-earmark-excel",
    "file-earmark-excel-fill",
    "file-earmark-fill",
    "file-earmark-font",
    "file-earmark-font-fill",
    "file-earmark-image",
    "file-earmark-image-fill",
    "file-earmark-lock",
    "file-earmark-lock-fill",
    "file-earmark-lock2",
    "file-earmark-lock2-fill",
    "file-earmark-medical",
    "file-earmark-medical-fill",
    "file-earmark-minus",
    "file-earmark-minus-fill",
    "file-earmark-music",
    "file-earmark-music-fill",
    "file-earmark-pdf",
    "file-earmark-pdf-fill",
    "file-earmark-person",
    "file-earmark-person-fill",
    "file-earmark-play",
    "file-earmark-play-fill",
    "file-earmark-plus",
    "file-earmark-plus-fill",
    "file-earmark-post",
    "file-earmark-post-fill",
    "file-earmark-ppt",
    "file-earmark-ppt-fill",
    "file-earmark-richtext",
    "file-earmark-richtext-fill",
    "file-earmark-ruled",
    "file-earmark-ruled-fill",
    "file-earmark-slides",
    "file-earmark-slides-fill",
    "file-earmark-spreadsheet",
    "file-earmark-spreadsheet-fill",
    "file-earmark-text",
    "file-earmark-text-fill",
    "file-earmark-word",
    "file-earmark-word-fill",
    "file-earmark-x",
    "file-earmark-x-fill",
    "file-earmark-zip",
    "file-earmark-zip-fill",
    "file-easel",
    "file-easel-fill",
    "file-excel",
    "file-excel-fill",
    "file-fill",
    "file-font",
    "file-font-fill",
    "file-image",
    "file-image-fill",
    "file-lock",
    "file-lock-fill",
    "file-lock2",
    "file-lock2-fill",
    "file-medical",
    "file-medical-fill",
    "file-minus",
    "file-minus-fill",
    "file-music",
    "file-music-fill",
    "file-pdf",
    "file-pdf-fill",
    "file-person",
    "file-person-fill",
    "file-play",
    "file-play-fill",
    "file-plus",
    "file-plus-fill",
    "file-post",
    "file-post-fill",
    "file-ppt",
    "file-ppt-fill",
    "file-richtext",
    "file-richtext-fill",
    "file-ruled",
    "file-ruled-fill",
    "file-slides",
    "file-slides-fill",
    "file-spreadsheet",
    "file-spreadsheet-fill",
    "file-text",
    "file-text-fill",
    "file-word",
    "file-word-fill",
    "file-x",
    "file-x-fill",
    "file-zip",
    "file-zip-fill",
    "files",
    "files-alt",
    "filetype-aac",
    "filetype-ai",
    "filetype-bmp",
    "filetype-cs",
    "filetype-css",
    "filetype-csv",
    "filetype-doc",
    "filetype-docx",
    "filetype-exe",
    "filetype-gif",
    "filetype-heic",
    "filetype-html",
    "filetype-java",
    "filetype-jpg",
    "filetype-js",
    "filetype-json",
    "filetype-jsx",
    "filetype-key",
    "filetype-m4p",
    "filetype-md",
    "filetype-mdx",
    "filetype-mov",
    "filetype-mp3",
    "filetype-mp4",
    "filetype-otf",
    "filetype-pdf",
    "filetype-php",
    "filetype-png",
    "filetype-ppt",
    "filetype-pptx",
    "filetype-psd",
    "filetype-py",
    "filetype-raw",
    "filetype-rb",
    "filetype-sass",
    "filetype-scss",
    "filetype-sh",
    "filetype-sql",
    "filetype-svg",
    "filetype-tiff",
    "filetype-tsx",
    "filetype-ttf",
    "filetype-txt",
    "filetype-wav",
    "filetype-woff",
    "filetype-xls",
    "filetype-xlsx",
    "filetype-xml",
    "filetype-yml",
    "film",
    "filter",
    "filter-circle",
    "filter-circle-fill",
    "filter-left",
    "filter-right",
    "filter-square",
    "filter-square-fill",
    "fingerprint",
    "fire",
    "flag",
    "flag-fill",
    "flower1",
    "flower2",
    "flower3",
    "folder",
    "folder-check",
    "folder-fill",
    "folder-minus",
    "folder-plus",
    "folder-symlink",
    "folder-symlink-fill",
    "folder-x",
    "folder2",
    "folder2-open",
    "fonts",
    "forward",
    "forward-fill",
    "front",
    "fuel-pump",
    "fuel-pump-diesel",
    "fuel-pump-diesel-fill",
    "fuel-pump-fill",
    "fullscreen",
    "fullscreen-exit",
    "funnel",
    "funnel-fill",
    "gear",
    "gear-fill",
    "gear-wide",
    "gear-wide-connected",
    "gem",
    "gender-ambiguous",
    "gender-female",
    "gender-male",
    "gender-trans",
    "geo",
    "geo-alt",
    "geo-alt-fill",
    "geo-fill",
    "gift",
    "gift-fill",
    "git",
    "github",
    "globe",
    "globe2",
    "google",
    "google-play",
    "gpu-card",
    "graph-down",
    "graph-down-arrow",
    "graph-up",
    "graph-up-arrow",
    "grid",
    "grid-1x2",
    "grid-1x2-fill",
    "grid-3x2",
    "grid-3x2-gap",
    "grid-3x2-gap-fill",
    "grid-3x3",
    "grid-3x3-gap",
    "grid-3x3-gap-fill",
    "grid-fill",
    "grip-horizontal",
    "grip-vertical",
    "h-circle",
    "h-circle-fill",
    "h-square",
    "h-square-fill",
    "hammer",
    "hand-index",
    "hand-index-fill",
    "hand-index-thumb",
    "hand-index-thumb-fill",
    "hand-thumbs-down",
    "hand-thumbs-down-fill",
    "hand-thumbs-up",
    "hand-thumbs-up-fill",
    "handbag",
    "handbag-fill",
    "hash",
    "hdd",
    "hdd-fill",
    "hdd-network",
    "hdd-network-fill",
    "hdd-rack",
    "hdd-rack-fill",
    "hdd-stack",
    "hdd-stack-fill",
    "hdmi",
    "hdmi-fill",
    "headphones",
    "headset",
    "headset-vr",
    "heart",
    "heart-arrow",
    "heart-fill",
    "heart-half",
    "heart-pulse",
    "heart-pulse-fill",
    "heartbreak",
    "heartbreak-fill",
    "hearts",
    "heptagon",
    "heptagon-fill",
    "heptagon-half",
    "hexagon",
    "hexagon-fill",
    "hexagon-half",
    "hospital",
    "hospital-fill",
    "hourglass",
    "hourglass-bottom",
    "hourglass-split",
    "hourglass-top",
    "house",
    "house-door",
    "house-door-fill",
    "house-fill",
    "house-heart",
    "house-heart-fill",
    "hr",
    "hurricane",
    "hypnotize",
    "image",
    "image-alt",
    "image-fill",
    "images",
    "inbox",
    "inbox-fill",
    "inboxes-fill",
    "inboxes",
    "incognito",
    "indent",
    "infinity",
    "info",
    "info-circle",
    "info-circle-fill",
    "info-lg",
    "info-square",
    "info-square-fill",
    "input-cursor",
    "input-cursor-text",
    "instagram",
    "intersect",
    "journal",
    "journal-album",
    "journal-arrow-down",
    "journal-arrow-up",
    "journal-bookmark",
    "journal-bookmark-fill",
    "journal-check",
    "journal-code",
    "journal-medical",
    "journal-minus",
    "journal-plus",
    "journal-richtext",
    "journal-text",
    "journal-x",
    "journals",
    "joystick",
    "justify",
    "justify-left",
    "justify-right",
    "kanban",
    "kanban-fill",
    "key",
    "key-fill",
    "keyboard",
    "keyboard-fill",
    "ladder",
    "lamp",
    "lamp-fill",
    "laptop",
    "laptop-fill",
    "layer-backward",
    "layer-forward",
    "layers",
    "layers-fill",
    "layers-half",
    "layout-sidebar",
    "layout-sidebar-inset-reverse",
    "layout-sidebar-inset",
    "layout-sidebar-reverse",
    "layout-split",
    "layout-text-sidebar",
    "layout-text-sidebar-reverse",
    "layout-text-window",
    "layout-text-window-reverse",
    "layout-three-columns",
    "layout-wtf",
    "life-preserver",
    "lightbulb",
    "lightbulb-fill",
    "lightbulb-off",
    "lightbulb-off-fill",
    "lightning",
    "lightning-charge",
    "lightning-charge-fill",
    "lightning-fill",
    "line",
    "link",
    "link-45deg",
    "linkedin",
    "list",
    "list-check",
    "list-columns",
    "list-columns-reverse",
    "list-nested",
    "list-ol",
    "list-stars",
    "list-task",
    "list-ul",
    "lock",
    "lock-fill",
    "lungs",
    "lungs-fill",
    "magic",
    "magnet",
    "magnet-fill",
    "mailbox",
    "mailbox2",
    "map",
    "map-fill",
    "markdown",
    "markdown-fill",
    "mask",
    "mastodon",
    "medium",
    "megaphone",
    "megaphone-fill",
    "memory",
    "menu-app",
    "menu-app-fill",
    "menu-button",
    "menu-button-fill",
    "menu-button-wide",
    "menu-button-wide-fill",
    "menu-down",
    "menu-up",
    "messenger",
    "meta",
    "mic",
    "mic-fill",
    "mic-mute",
    "mic-mute-fill",
    "microsoft",
    "microsoft-teams",
    "minecart",
    "minecart-loaded",
    "modem",
    "modem-fill",
    "moisture",
    "moon",
    "moon-fill",
    "moon-stars",
    "moon-stars-fill",
    "mortarboard",
    "mortarboard-fill",
    "motherboard",
    "motherboard-fill",
    "mouse",
    "mouse-fill",
    "mouse2",
    "mouse2-fill",
    "mouse3",
    "mouse3-fill",
    "music-note",
    "music-note-beamed",
    "music-note-list",
    "music-player",
    "music-player-fill",
    "newspaper",
    "nintendo-switch",
    "node-minus",
    "node-minus-fill",
    "node-plus",
    "node-plus-fill",
    "nut",
    "nut-fill",
    "octagon",
    "octagon-fill",
    "octagon-half",
    "optical-audio",
    "optical-audio-fill",
    "option",
    "outlet",
    "p-circle",
    "p-circle-fill",
    "p-square",
    "p-square-fill",
    "paint-bucket",
    "palette",
    "palette-fill",
    "palette2",
    "paperclip",
    "paragraph",
    "pass",
    "pass-fill",
    "patch-check",
    "patch-check-fill",
    "patch-exclamation",
    "patch-exclamation-fill",
    "patch-minus",
    "patch-minus-fill",
    "patch-plus",
    "patch-plus-fill",
    "patch-question",
    "patch-question-fill",
    "pause",
    "pause-btn",
    "pause-btn-fill",
    "pause-circle",
    "pause-circle-fill",
    "pause-fill",
    "paypal",
    "pc",
    "pc-display",
    "pc-display-horizontal",
    "pc-horizontal",
    "pci-card",
    "peace",
    "peace-fill",
    "pen",
    "pen-fill",
    "pencil",
    "pencil-fill",
    "pencil-square",
    "pentagon",
    "pentagon-fill",
    "pentagon-half",
    "people",
    "person-circle",
    "people-fill",
    "percent",
    "person",
    "person-badge",
    "person-badge-fill",
    "person-bounding-box",
    "person-check",
    "person-check-fill",
    "person-dash",
    "person-dash-fill",
    "person-fill",
    "person-heart",
    "person-hearts",
    "person-lines-fill",
    "person-plus",
    "person-plus-fill",
    "person-rolodex",
    "person-square",
    "person-video",
    "person-video2",
    "person-video3",
    "person-workspace",
    "person-x",
    "person-x-fill",
    "phone",
    "phone-fill",
    "phone-flip",
    "phone-landscape",
    "phone-landscape-fill",
    "phone-vibrate",
    "phone-vibrate-fill",
    "pie-chart",
    "pie-chart-fill",
    "piggy-bank",
    "piggy-bank-fill",
    "pin",
    "pin-angle",
    "pin-angle-fill",
    "pin-fill",
    "pin-map",
    "pin-map-fill",
    "pinterest",
    "pip",
    "pip-fill",
    "play",
    "play-btn",
    "play-btn-fill",
    "play-circle",
    "play-circle-fill",
    "play-fill",
    "playstation",
    "plug",
    "plug-fill",
    "plugin",
    "plus",
    "plus-circle",
    "plus-circle-dotted",
    "plus-circle-fill",
    "plus-lg",
    "plus-slash-minus",
    "plus-square",
    "plus-square-dotted",
    "plus-square-fill",
    "postage",
    "postage-fill",
    "postage-heart",
    "postage-heart-fill",
    "postcard",
    "postcard-fill",
    "postcard-heart",
    "postcard-heart-fill",
    "power",
    "prescription",
    "prescription2",
    "printer",
    "printer-fill",
    "projector",
    "projector-fill",
    "puzzle",
    "puzzle-fill",
    "qr-code",
    "qr-code-scan",
    "question",
    "question-circle",
    "question-diamond",
    "question-diamond-fill",
    "question-circle-fill",
    "question-lg",
    "question-octagon",
    "question-octagon-fill",
    "question-square",
    "question-square-fill",
    "quora",
    "quote",
    "r-circle",
    "r-circle-fill",
    "r-square",
    "r-square-fill",
    "radioactive",
    "rainbow",
    "receipt",
    "receipt-cutoff",
    "reception-0",
    "reception-1",
    "reception-2",
    "reception-3",
    "reception-4",
    "record",
    "record-btn",
    "record-btn-fill",
    "record-circle",
    "record-circle-fill",
    "record-fill",
    "record2",
    "record2-fill",
    "recycle",
    "reddit",
    "repeat",
    "repeat-1",
    "reply",
    "reply-all",
    "reply-all-fill",
    "reply-fill",
    "rewind",
    "rewind-btn",
    "rewind-btn-fill",
    "rewind-circle",
    "rewind-circle-fill",
    "rewind-fill",
    "robot",
    "router",
    "router-fill",
    "rss",
    "rss-fill",
    "rulers",
    "safe",
    "safe-fill",
    "safe2",
    "safe2-fill",
    "save",
    "save-fill",
    "save2",
    "save2-fill",
    "scissors",
    "screwdriver",
    "sd-card",
    "sd-card-fill",
    "search",
    "search-heart",
    "search-heart-fill",
    "segmented-nav",
    "send",
    "send-check",
    "send-check-fill",
    "send-dash",
    "send-dash-fill",
    "send-exclamation",
    "send-exclamation-fill",
    "send-fill",
    "send-plus",
    "send-plus-fill",
    "send-slash",
    "send-slash-fill",
    "send-x",
    "send-x-fill",
    "server",
    "share",
    "share-fill",
    "shield",
    "shield-check",
    "shield-exclamation",
    "shield-fill",
    "shield-fill-check",
    "shield-fill-exclamation",
    "shield-fill-minus",
    "shield-fill-plus",
    "shield-fill-x",
    "shield-lock",
    "shield-lock-fill",
    "shield-minus",
    "shield-plus",
    "shield-shaded",
    "shield-slash",
    "shield-slash-fill",
    "shield-x",
    "shift",
    "shift-fill",
    "shop",
    "shop-window",
    "shuffle",
    "sign-stop",
    "sign-stop-fill",
    "sign-stop-lights",
    "sign-stop-lights-fill",
    "sign-turn-left",
    "sign-turn-left-fill",
    "sign-turn-right",
    "sign-turn-right-fill",
    "sign-turn-slight-left",
    "sign-turn-slight-left-fill",
    "sign-turn-slight-right",
    "sign-turn-slight-right-fill",
    "sign-yield",
    "sign-yield-fill",
    "signal",
    "signpost",
    "signpost-2",
    "signpost-2-fill",
    "signpost-fill",
    "signpost-split",
    "signpost-split-fill",
    "sim",
    "sim-fill",
    "skip-backward",
    "skip-backward-btn",
    "skip-backward-btn-fill",
    "skip-backward-circle",
    "skip-backward-circle-fill",
    "skip-backward-fill",
    "skip-end",
    "skip-end-btn",
    "skip-end-btn-fill",
    "skip-end-circle",
    "skip-end-circle-fill",
    "skip-end-fill",
    "skip-forward",
    "skip-forward-btn",
    "skip-forward-btn-fill",
    "skip-forward-circle",
    "skip-forward-circle-fill",
    "skip-forward-fill",
    "skip-start",
    "skip-start-btn",
    "skip-start-btn-fill",
    "skip-start-circle",
    "skip-start-circle-fill",
    "skip-start-fill",
    "skype",
    "slack",
    "slash",
    "slash-circle-fill",
    "slash-lg",
    "slash-square",
    "slash-square-fill",
    "sliders",
    "sliders2",
    "sliders2-vertical",
    "smartwatch",
    "snapchat",
    "snow",
    "snow2",
    "snow3",
    "sort-alpha-down",
    "sort-alpha-down-alt",
    "sort-alpha-up",
    "sort-alpha-up-alt",
    "sort-down",
    "sort-down-alt",
    "sort-numeric-down",
    "sort-numeric-down-alt",
    "sort-numeric-up",
    "sort-numeric-up-alt",
    "sort-up",
    "sort-up-alt",
    "soundwave",
    "speaker",
    "speaker-fill",
    "speedometer",
    "speedometer2",
    "spellcheck",
    "spotify",
    "square",
    "square-fill",
    "square-half",
    "stack",
    "stack-overflow",
    "star",
    "star-fill",
    "star-half",
    "stars",
    "steam",
    "stickies",
    "stickies-fill",
    "sticky",
    "sticky-fill",
    "stop",
    "stop-btn",
    "stop-btn-fill",
    "stop-circle",
    "stop-circle-fill",
    "stop-fill",
    "stoplights",
    "stoplights-fill",
    "stopwatch",
    "stopwatch-fill",
    "strava",
    "subtract",
    "suit-club",
    "suit-club-fill",
    "suit-diamond",
    "suit-diamond-fill",
    "suit-heart",
    "suit-heart-fill",
    "suit-spade",
    "suit-spade-fill",
    "sun",
    "sun-fill",
    "sunglasses",
    "sunrise",
    "sunrise-fill",
    "sunset",
    "sunset-fill",
    "symmetry-horizontal",
    "symmetry-vertical",
    "table",
    "tablet",
    "tablet-fill",
    "tablet-landscape",
    "tablet-landscape-fill",
    "tag",
    "tag-fill",
    "tags",
    "tags-fill",
    "telegram",
    "telephone",
    "telephone-fill",
    "telephone-forward",
    "telephone-forward-fill",
    "telephone-inbound",
    "telephone-inbound-fill",
    "telephone-minus",
    "telephone-minus-fill",
    "telephone-outbound",
    "telephone-outbound-fill",
    "telephone-plus",
    "telephone-plus-fill",
    "telephone-x",
    "telephone-x-fill",
    "terminal",
    "terminal-dash",
    "terminal-fill",
    "terminal-plus",
    "terminal-split",
    "terminal-x",
    "text-center",
    "text-indent-left",
    "text-indent-right",
    "text-left",
    "text-paragraph",
    "text-right",
    "textarea",
    "textarea-resize",
    "textarea-t",
    "thermometer",
    "thermometer-half",
    "thermometer-high",
    "thermometer-low",
    "thermometer-snow",
    "thermometer-sun",
    "three-dots",
    "three-dots-vertical",
    "thunderbolt",
    "thunderbolt-fill",
    "ticket",
    "ticket-detailed",
    "ticket-detailed-fill",
    "ticket-fill",
    "ticket-perforated",
    "ticket-perforated-fill",
    "tiktok",
    "toggle-off",
    "toggle-on",
    "toggle2-off",
    "toggle2-on",
    "toggles",
    "toggles2",
    "tools",
    "tornado",
    "train-freight-front",
    "train-freight-front-fill",
    "train-front",
    "train-front-fill",
    "train-lightrail-front",
    "train-lightrail-front-fill",
    "translate",
    "trash",
    "trash-fill",
    "trash2",
    "trash2-fill",
    "trash3",
    "trash3-fill",
    "tree",
    "tree-fill",
    "triangle",
    "triangle-fill",
    "triangle-half",
    "trophy",
    "trophy-fill",
    "tropical-storm",
    "truck",
    "truck-flatbed",
    "truck-front",
    "truck-front-fill",
    "tsunami",
    "tv",
    "tv-fill",
    "twitch",
    "twitter",
    "type",
    "type-bold",
    "type-h1",
    "type-h2",
    "type-h3",
    "type-italic",
    "type-strikethrough",
    "type-underline",
    "ubuntu",
    "ui-checks",
    "ui-checks-grid",
    "ui-radios",
    "ui-radios-grid",
    "umbrella",
    "umbrella-fill",
    "unindent",
    "union",
    "unity",
    "universal-access",
    "universal-access-circle",
    "unlock",
    "unlock-fill",
    "upc",
    "upc-scan",
    "upload",
    "usb",
    "usb-c",
    "usb-c-fill",
    "usb-drive",
    "usb-drive-fill",
    "usb-fill",
    "usb-micro",
    "usb-micro-fill",
    "usb-mini",
    "usb-mini-fill",
    "usb-plug",
    "usb-plug-fill",
    "usb-symbol",
    "valentine",
    "valentine2",
    "vector-pen",
    "view-list",
    "view-stacked",
    "vimeo",
    "vinyl",
    "vinyl-fill",
    "virus",
    "virus2",
    "voicemail",
    "volume-down",
    "volume-down-fill",
    "volume-mute",
    "volume-mute-fill",
    "volume-off",
    "volume-off-fill",
    "volume-up",
    "volume-up-fill",
    "vr",
    "wallet",
    "wallet-fill",
    "wallet2",
    "watch",
    "water",
    "webcam",
    "webcam-fill",
    "wechat",
    "whatsapp",
    "wifi",
    "wifi-1",
    "wifi-2",
    "wifi-off",
    "wind",
    "window",
    "window-dash",
    "window-desktop",
    "window-dock",
    "window-fullscreen",
    "window-plus",
    "window-sidebar",
    "window-split",
    "window-stack",
    "window-x",
    "windows",
    "wordpress",
    "wrench",
    "wrench-adjustable",
    "wrench-adjustable-circle",
    "wrench-adjustable-circle-fill",
    "x",
    "x-circle",
    "x-circle-fill",
    "x-diamond",
    "x-diamond-fill",
    "x-lg",
    "x-octagon",
    "x-octagon-fill",
    "x-square",
    "x-square-fill",
    "xbox",
    "yelp",
    "yin-yang",
    "youtube",
    "zoom-in",
    "zoom-out"
];