import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-12 col-md-6 col-lg-4 col-xl-3"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "col" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "col-12 col-md-6 col-lg-4 col-xl-3"
}
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "html-1 d-flex py-3",
    style: _normalizeStyle({backgroundColor: _ctx.config.bgColor})
  }, [
    _createElementVNode("div", {
      class: "mx-auto row",
      style: _normalizeStyle({maxWidth: _ctx.config.maxWidth})
    }, [
      (_ctx.config.image && _ctx.config.imgPosition === 'left')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              style: _normalizeStyle({height: _ctx.config.imgHeightEm + 'em'})
            }, [
              _createElementVNode("img", {
                src: _ctx.config.image.itemSrc,
                class: "image-cover",
                alt: _ctx.config.image.alt
              }, null, 8, _hoisted_2)
            ], 4)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          innerHTML: _ctx.config.html
        }, null, 8, _hoisted_4)
      ]),
      (_ctx.config.image && _ctx.config.imgPosition === 'right')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", {
              style: _normalizeStyle({height: _ctx.config.imgHeightEm + 'em'})
            }, [
              _createElementVNode("img", {
                src: _ctx.config.image.itemSrc,
                class: "image-cover",
                alt: _ctx.config.image.alt
              }, null, 8, _hoisted_6)
            ], 4)
          ]))
        : _createCommentVNode("", true)
    ], 4)
  ], 4))
}