import { ConfigProp } from "@/config/ConfigProp";

export class ContainerConfig {
    public static get Config(): { [key: string]: ConfigProp } {
        return {
            "style": {
                "type": "number",
                "format": "style",
                "required": true
            },
            "config": {
                "type": "object",
                "format": "config",
                "required": true
            }
        };
    }
}