import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.icon.source === 'bi')
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          tabindex: "-1",
          class: _normalizeClass(["bi", 'bi-'+_ctx.icon.name]),
          style: _normalizeStyle(_ctx.icon.color ? 'color: ' + _ctx.icon.color + ';' : '')
        }, null, 6))
      : _createCommentVNode("", true),
    (_ctx.icon.source === 'mi')
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          tabindex: "-1",
          class: "material-icons",
          style: _normalizeStyle(_ctx.icon.color ? 'color: ' + _ctx.icon.color + ';' : '')
        }, _toDisplayString(_ctx.icon.name), 5))
      : _createCommentVNode("", true)
  ], 64))
}