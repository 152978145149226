import { ConfigProp } from "@/config/ConfigProp";

export class Container1Config {
    public static get Config(): { [key: string]: ConfigProp } {
        return {
            "headerHeightEm": {
                "label": "heightEm",
                "type": "number",
                "required": true,
                "format": "decimal",
                "preDefined": [ {
                    icon: {
                        name: "fullscreen-exit", source: "bi"
                    },
                    value: 5
                }, {
                    icon: {
                        name: "fullscreen", source: "bi"
                    },
                    value: 8
                }, {
                    icon: {
                        name: "arrows-fullscreen", source: "bi"
                    },
                    value: 12
                }
                ]
            },
            "logo": {
                "type": "object",
                "format": "image"
            }
        };
    }

    public static Empty( i18n ) {
        return {
            style: 1,
            headerHeightEm: 3
        };
    }
}