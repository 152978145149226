

import { defineComponent, onMounted, ref, watch } from "vue";

const lodash = require( "lodash" );

export default defineComponent( {
    name: 'WebsiteConfigurator',
    components: {},
    props: {
        config: {
            type: Object
        },
        content: {
            type: Object
        }
    },
    emits: [ "change" ],
    setup( props, { emit } ) {
        const currConfig = ref( null );

        onMounted( () => {
            currConfig.value = lodash.cloneDeep( props.config );
        } );

        function updateConfig( prop, val ) {
            currConfig.value[prop] = val;
            emit( "change", currConfig.value );
        }

        return {
            currConfig,
            updateConfig
        };
    }
} );
