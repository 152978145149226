import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100 mt-3 d-flex position-relative"
}
const _hoisted_2 = { class: "p-inputgroup position-relative" }
const _hoisted_3 = { class: "p-float-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_ctx.currVal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.currVal.text,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currVal.text) = $event)),
              onInput: _ctx.change
            }, null, 8, ["modelValue", "onInput"]),
            _createElementVNode("label", null, _toDisplayString(_ctx.$t( 'editor.header' )), 1)
          ]),
          _createElementVNode("span", {
            class: "p-inputgroup-addon",
            onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.currVal.class = _ctx.orientationMap[_ctx.Orientation.LEFT]; _ctx.change()})
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["bi bi-justify-left", _ctx.currVal.class === _ctx.orientationMap[_ctx.Orientation.LEFT] ? 'text-black' : ''])
            }, null, 2)
          ]),
          _createElementVNode("span", {
            class: "p-inputgroup-addon",
            onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.currVal.class = _ctx.orientationMap[_ctx.Orientation.CENTER]; _ctx.change()})
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["bi bi-justify", _ctx.currVal.class === _ctx.orientationMap[_ctx.Orientation.CENTER] ? 'text-black' : ''])
            }, null, 2)
          ]),
          _createElementVNode("span", {
            class: "p-inputgroup-addon",
            onClick: _cache[3] || (_cache[3] = ($event: any) => {_ctx.currVal.class = _ctx.orientationMap[_ctx.Orientation.RIGHT]; _ctx.change()})
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["bi bi-justify-right", _ctx.currVal.class === _ctx.orientationMap[_ctx.Orientation.RIGHT] ? 'text-black' : ''])
            }, null, 2)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}