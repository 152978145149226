

import { defineComponent, onMounted, PropType, Ref, ref, watch } from "vue";
import Dropdown from "primevue/dropdown";
import Icon from "@/modules/general/Icon.vue";
import { IIcon } from "@/utils/interfaces/IIcon";
import ColorPicker from "primevue/colorpicker";
import InputText from "primevue/inputtext";
import { biIcons } from "./icons/bi-icons";
import { miIcons } from "./icons/mi-icons";
import CustomColorPicker from "@/modules/editor/CustomColorPicker.vue";

export default defineComponent( {
    name: 'IconPicker',
    components: { CustomColorPicker, Icon, Dropdown, InputText },
    props: {
        icon: {
            type: Object as PropType<IIcon>,
        }
    },
    emits: [ "change" ],
    setup( props, { emit } ) {
        const iconSources = [
            { icon: { name: "bootstrap", source: "bi" }, code: 'bi' },
            { icon: { name: "google", source: "bi" }, code: 'mi' }
        ];
        const currSource = ref( iconSources[0] );
        const currSuggestions: Ref<string[]> = ref( [] );
        const filteredSuggestions: Ref<string[]> = ref( [] );
        const showSuggestions: Ref<boolean> = ref( false );
        const symbolSearch: Ref<string> = ref( "" );
        const currIcon: Ref<IIcon> = ref( null );
        watch( currSource, ( newValue, oldValue ) => {
            currIcon.value.source = currSource.value.code;
            loadSuggestion();
        } );

        let timeOut = null;

        onMounted( () => {
            if( props.icon )
                currIcon.value = props.icon;
            else
                currIcon.value = { name: '', source: currSource.value.code, color: null };
            loadSuggestion();

            watch( currIcon.value, ( newValue, oldValue ) => {
                if( timeOut )
                    clearTimeout( timeOut );
                timeOut = setTimeout( change, 1000 );
            } );
        } );

        function change() {
            emit( 'change', currIcon.value );
        }

        function loadSuggestion() {
            symbolSearch.value = "";
            switch( currSource.value.code ) {
                case "bi":
                    currSuggestions.value = biIcons;
                    break;
                case "mi":
                    currSuggestions.value = miIcons;
                    break;
                default:
                    break;
            }
            filteredSuggestions.value = currSuggestions.value;
        }

        function filterSuggestions() {
            filteredSuggestions.value = currSuggestions.value.filter( s => s.indexOf( symbolSearch.value ) != -1 );
        }

        function hideSuggestions( event ) {
            const parent = event.originalTarget.closest( "#parent" );
            if( !parent.matches( ':focus-within' ) ) {
                showSuggestions.value = false;
            }
        }

        function changeIcon( icon ) {
            currIcon.value.name = icon;
            showSuggestions.value = false;
        }


        return {
            currIcon,
            currSource,
            iconSources,
            currSuggestions,
            showSuggestions,
            filteredSuggestions,
            symbolSearch,
            filterSuggestions,
            hideSuggestions,
            changeIcon
        };
    }
} );
