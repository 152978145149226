import { ConfigProp } from "@/config/ConfigProp";

/********************
 * WARNING:
 * This is unused because the icon is get by a special input
 */
export class IconConfig {
    public static get Config(): { [key: string]: ConfigProp } {
        return {
            "source": {
                "type": "string",
                "format": "enum",
                "values": [
                    "bi",
                    "mi"
                ],
                "required": true
            },
            "name": {
                "type": "string",
                "description": "name of icon",
                "example": "'google' from https://icons.getbootstrap.com/",
                "required": true
            }
        };
    }
}