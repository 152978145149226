import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HTML_1 = _resolveComponent("HTML_1")!

  return (_ctx.type===1)
    ? (_openBlock(), _createBlock(_component_HTML_1, {
        key: 0,
        config: _ctx.config
      }, null, 8, ["config"]))
    : _createCommentVNode("", true)
}