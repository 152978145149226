import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "overflow-auto h-100"
}
const _hoisted_2 = { class: "config-grid w-100 t1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfigProperty = _resolveComponent("ConfigProperty")!

  return (_ctx.currConfig)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.getOwnPropertyNames(_ctx.content).filter(i => !_ctx.content[i].hidden && !((_ctx.content[i].type === 'object' && _ctx.content[i].format === 'paragraph')  || _ctx.content[i].type === 'array')), (prop) => {
            return (_openBlock(), _createBlock(_component_ConfigProperty, {
              class: "grid-element",
              property: _ctx.content[prop],
              value: _ctx.currConfig[prop],
              id: prop,
              onChange: ($event: any) => (_ctx.updateConfig(prop,$event))
            }, null, 8, ["property", "value", "id", "onChange"]))
          }), 256))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.getOwnPropertyNames(_ctx.content).filter(i => !_ctx.content[i].hidden && ((_ctx.content[i].type === 'object' && _ctx.content[i].format === 'paragraph') || _ctx.content[i].type === 'array')), (prop) => {
          return (_openBlock(), _createBlock(_component_ConfigProperty, {
            property: _ctx.content[prop],
            value: _ctx.currConfig[prop],
            id: prop,
            onChange: ($event: any) => (_ctx.updateConfig(prop,$event))
          }, null, 8, ["property", "value", "id", "onChange"]))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}