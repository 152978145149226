

import { defineComponent, PropType } from "vue";
import Events_1 from "@/modules/events/Events_1.vue";

export default defineComponent( {
    name: 'EventsSwitch',
    components: { Events_1 },
    props: {
        config: {
            type: Object as PropType<any>
        },
        type: {
            type: Number
        }
    },
    setup() {
    }
} );
