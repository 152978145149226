import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex flex-wrap w-100", _ctx.center ? 'justify-content-evenly' : 'justify-content-between' ]),
    id: _ctx.id
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.placeholderCount, (i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        style: _normalizeStyle({width: _ctx.childWidth + 'px'} )
      }, null, 4))
    }), 128))
  ], 10, _hoisted_1))
}