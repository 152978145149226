

import { defineComponent, PropType, Ref, ref } from "vue";
import { EventDto } from "@/api";

interface Props {
    event: EventDto,
    id: string
}

export default defineComponent( {
    name: 'EventDetailModal',
    components: {},
    props: {
        event: {
            type: Object as PropType<EventDto>
        },
        id: {
            type: String
        }
    },
    setup( props: Props ) {

    }
} );
