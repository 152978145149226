

import { defineComponent } from "vue";

export default defineComponent( {
    name: 'PrivacyStatement',
    components: {},
    props: {
    },
    setup() {
    }
} );
