import { ConfigProp } from "@/config/ConfigProp";

const MAX_EVENTS = 5;
const DEFAULT_BG = "";

export class Events1Config {
    public static get Config(): { [key: string]: ConfigProp } {
        return {
            "maxEvents": {
                "type": "number",
                "format": "integer",
                "required": true,
                "default": MAX_EVENTS
            },
            "bgColor": {
                "type": "string",
                "required": true,
                "default": DEFAULT_BG,
                "format": "color"
            },
            "maxWidth": {
                "type": "string",
                "preDefined": [ {
                    icon: {
                        name: "fullscreen-exit", source: "bi"
                    },
                    value: "min(1200px,100%)"
                }, {
                    icon: {
                        name: "fullscreen", source: "bi"
                    },
                    value: "min(1600px,100%)"
                }, {
                    icon: {
                        name: "arrows-fullscreen", source: "bi"
                    },
                    value: "100%"
                }
                ]
            },
        };
    }

    public static Empty( i18n ) {
        return {
            type: "events",
            title: {},
            style: 1,
            name: i18n.t( 'common.unnamed' ),
            config: {
                maxEvents: MAX_EVENTS,
                bgColor: DEFAULT_BG
            }
        };
    }
}