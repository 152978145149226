import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 mt-auto pb-2" }
const _hoisted_2 = { class: "ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Checkbox, {
      modelValue: _ctx.currValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currValue) = $event)),
      binary: true,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateConfig()))
    }, null, 8, ["modelValue"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t( 'editor.' + (_ctx.property.label ? _ctx.property.label : _ctx.id) )), 1)
  ]))
}