import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 1,
  class: "mx-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventDetailModal = _resolveComponent("EventDetailModal")!
  const _component_EventCard = _resolveComponent("EventCard")!
  const _component_SplitCarouselItem = _resolveComponent("SplitCarouselItem")!
  const _component_SplitCarousel = _resolveComponent("SplitCarousel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_EventDetailModal, {
      id: _ctx.id + 'DetailModal',
      event: _ctx.currEvent
    }, null, 8, ["id", "event"]),
    _createElementVNode("div", {
      class: "d-flex py-3",
      style: _normalizeStyle({backgroundColor: _ctx.config.bgColor}),
      id: _ctx.id
    }, [
      (_ctx.upcomingEvents && _ctx.upcomingEvents.data && _ctx.upcomingEvents.data.length > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "mx-auto",
            style: _normalizeStyle({width: 'min( ' + _ctx.config.maxWidth + ', 100% )'})
          }, [
            _createVNode(_component_SplitCarousel, {
              autoplay: false,
              "item-width": 300,
              height: 200,
              loop: false
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.upcomingEvents.data, (event) => {
                  return (_openBlock(), _createBlock(_component_SplitCarouselItem, {
                    key: event,
                    class: "cursor-pointer"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_EventCard, {
                        class: "m-3",
                        onClick: ($event: any) => (_ctx.selectEvent(event)),
                        "data-bs-toggle": "modal",
                        "data-bs-target": '#'+ _ctx.id + 'DetailModal',
                        event: event
                      }, null, 8, ["onClick", "data-bs-target", "event"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ], 4))
        : (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.$t( 'events.noUpcomingEvents' )), 1))
    ], 12, _hoisted_1)
  ], 64))
}