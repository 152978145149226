export abstract class ConfigService {

    /**
     * Static configs
     */

    public static getConfig() {
        return process.env.VUE_APP_CONFIG;
    }

    public static getFallbackLang() {
        return process.env.VUE_APP_DEFAULT_LANG;
    }

    static getHubBaseUrl() {
        return process.env.VUE_APP_HUB_BASE_URL;
    }
}