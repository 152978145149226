

import { defineComponent, onMounted, PropType, Ref, ref, watch } from "vue";
import { IHeader } from "@/utils/interfaces/IHeader";
import InputText from "primevue/inputtext";

enum Orientation {
    LEFT,
    CENTER,
    RIGHT
}

const lodash = require( "lodash" );

export default defineComponent( {
    name: 'HeaderConfigurator',
    components: {
        InputText
    },
    props: {
        value: {
            type: Object as PropType<IHeader>
        },
        id: {
            type: String
        }
    },
    emits: [ "change" ],
    setup( props, { emit } ) {
        const currVal: Ref<IHeader> = ref( null );

        const orientationMap = {};
        orientationMap[Orientation.LEFT] = "text-start";
        orientationMap[Orientation.CENTER] = "text-center";
        orientationMap[Orientation.RIGHT] = "text-end ";


        onMounted( () => {
            currVal.value = lodash.cloneDeep( props.value );
        } );

        function change() {
            emit( "change", currVal.value );
        }

        return {
            Orientation,
            orientationMap,
            change,
            currVal
        };
    }
} );
