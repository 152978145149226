export default {
    location: "de-AT",
    common: {
        ok: "OK",
        legalNotice: "Impressum",
        privacyStatement: "Datenschutzerklärung",
        select: "Auswählen",
        noElements: "Keine Elemente",
        cancel: "Abbrechen",
        unnamed: "Unbenannt",
    },
    enum: {
        left: "Links",
        right: "Rechts",
        center: "Mitte",
        bi: "Bootstrap",
        mi: "Material",
        gallery: "Gallerie",
        html: "HTML",
        events: "Termine",
        partners: "Partner/Sponsoren",
        undefined: "Undefiniert"
    },
    editor: {
        editor: "Websiteeditor",
        showCode: "Code anzeigen",
        hideCode: "Code ausblenden",
        showFullScreen: "Vollbild",
        hideFullScreen: "Editor anzeigen",
        icon: "Symbol",
        display: {
            name: "Name anzeigen"
        },
        inMenu: "In Menü anzeigen",
        name: "Name",
        style: "Style",
        type: "Typ",
        responsible: "Verantwortlich",
        legal: "Gesetzliche Informationen",
        position: "Position",
        link: "URL",
        carNumber: "Vereinsnummer",
        email: "Email",
        phone: "Telefon",
        title: "Titel",
        favicon: "Favicon",
        subtitle: "Untertitel",
        container: "Theme",
        nameOfClub: "Vereinsname",
        street: "Straße",
        city: "Stadt",
        state: "Staat",
        country: "Staat",
        height: "Höhe",
        width: "Weite",
        manual: "Händisch",
        showNavigation: "Navigation anzeigen",
        autoSwitchTimer: "Automatisch weiterschalten (Sekunden)",
        maxWidth: "Maximale Weite",
        maxHeightEm: "Maximale Höhe in em",
        heightEm: "Höhe in em",
        undefined: "Unbekannt",
        gallery: "Gallerie",
        html: "Html",
        bgColor: "Hintergrundfarbe",
        image: "Bild",
        maxEvents: "Maximale Eventanzahl",
        menus: "Menüs",
        modules: "Abschnitte",
        additionalItems: "Zusätzliche Elemente",
        partners: "Partner",
        images: "Bilder",
        header: "Titel",
        defaultConfigDescription: "Neue Version vom {date}",
        publish: "Veröffentlichen",
        publishMessage: "Versionsnotiz",
        downloadCode: "Code speichern",
        uploadCode: "Code hochladen",
        backups: "Sicherungen",
        description: "Beschreibung",
        changeDate: "Änderungsdatum",
        version: "Version",
        path: "Pfad",
        pages: "Seiten",
        general: "Allgemein",
        club: "Verein",
        logo: "Logo"
    },
    sitemap: {
        legalNotice: "/impressum",
        privacyStatement: "/datenschutzerklärung",
    },
    legal: {
        contentTitle: "Für den Inhalt verantwortlich",
        hostingTitle: "Hosting & Software",
        hostingContentHTML: "<a href='https://www.soctio.at'>Soctio, Tirol</a> <br> Baue auch du deine Vereins-Website über den Soctio. Oder suche Events in deiner Nähe über <a href='https://www.events.soctio.at'>www.events.soctio.at</a>",
        carNumber: "ZVR-Nummer",
        rightOfUseTitle: "Nutzungsrechte & Urheberrechte",
        rightOfUseContent: "Die Inhalte dieser Website sind urheberrechtlich geschützt. Bestimmte Inhalte können auch den Urheberrechten und Nutzungsbestimmungen Dritter unterliegen. Inhalte dieser Website dürfen nur mit ausdrücklicher Erlaubnis von '{club}' auf anderen Websiten verwendet werden. <br> Die Inhalte werden nach besten Wissen auf Richtigkeit erstellt. Inhaltliche und sachliche Fehler sind nicht vollständig auszuschließen. {club} übernimmt keine Garantie und Haftung für die Richtigkeit, Aktualität und Vollständigkeit der bereitgestellten Informationen. Alle Angaben sind ohne Gewähr. <br> Wir übernehmen keine Haftung für Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.",
        privacyStatementTitle: "Datenschutz",
        privacyStatementContent: "Mehr zum Datenschutz erfahren Sie <a href='{link}'>hier</a>"
    },
    privacyStatement: {
        generalTitle: "Allgemeines",
        generalContent: "Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.",
        cookiesTitle: "Cookies",
        cookiesContent: "Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.",
        sslEncryptionTitle: "SSL-Verschlüsselung",
        sslEncryptionContent: "Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden."
    },
    events: {
        noUpcomingEvents: "Keine anstehenden Events"
    },
    partners: {
        logoOfPartner: "Logo von {partner}"
    },
    icons: {
        bi: "Bootstrap",
        mi: "Material Icons"
    },
    notification: {
        success: "OK",
        warn: "WARNUNG",
        info: "INFO",
        infos: {
            previewNoMenus: "Menüs sind während der Bearbeitung in der Vorschau deaktiviert"
        },
        error: "FEHLER",
        errors: {
            regexInvalidMatch: "Der Text hält Regeln nicht ein.",
            whileCommunicatingWithServer: "Kommunikation mit dem Server ist fehlgeschlagen.",
            saveConfigManually: "Die Konfiguration konnte nicht gespeichert werden. Bitte speichere die Konfiguration über 'Code speichern' und lade ihn später über 'Code hochladen' wieder hinauf.",
            configWrongFormat: "Konfigurationdatei hat ein falsches Format."
        },
        successes: {
            uploadComplete: "Erfolgreich Hochgeladen"
        }
    },
    tooltip: {
        back: "Zurück",
        forward: "Wiederanwenden",
        getBackup: "Backup wiederherstellen",
        resetToLastPublishedVersion: "Zur letzt veröffentlichten Version zurücksetzen",
        sort: "Sortieren",
        copy: "Kopieren",
        add: "Hinzufügen",
        delete: "Löschen",
        addModul: "Abschnitt hinzufügen",
        sortUp: "Nach oben verschieben",
        sortDown: "Nach unten verschieben",
        sortLeft: "Nach links verschieben",
        sortRight: "Nach rechts verschieben",
    }
}