import { IMenuItem } from "@/utils/interfaces/IMenuItem";
import { IModule } from "@/utils/interfaces/IModule";

export function moveArrayItem( arr: any[], oldIndex: number, new_index ) {
    if( new_index >= arr.length ) {
        let k = new_index - arr.length + 1;
        while( k-- ) {
            arr.push( undefined );
        }
    }
    arr.splice( new_index, 0, arr.splice( oldIndex, 1 )[0] );
    return arr; // for testing
}

export function isObject( obj ) {
    return typeof obj === 'object' && obj !== null;
}

export function getMenu( pages ): IMenuItem[] {
    if( pages.length == 1 ) {
        return modulesToMenuItems( pages[0].modules );
    }
    let items: IMenuItem[] = [];
    for( const page of pages ) {
        items.push( {
            name: page.name,
            path: '/' + page.path,
            children: modulesToMenuItems( page.modules, page.path )
        } );
    }
    return items;
}

function modulesToMenuItems( modules: IModule[], subPath: string = "/" ): IMenuItem[] {
    let items: IMenuItem[] = [];
    for( const [ index, module ] of modules.entries() ) {
        items.push( { name: module.name, path: subPath + '#' + module.type + index, display: module.inMenu } );
    }
    return items;
}