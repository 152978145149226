

import { defineComponent } from "vue";

export default defineComponent( {
    name: 'HTML_1',
    components: {},
    props: {
        config: {
            type: Object
        },
        id: {
            type: String
        }
    },
    setup() {
    }
} );
