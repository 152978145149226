

import { defineComponent, PropType } from "vue";
import Partners_1 from "@/modules/partners/Partners_1.vue";

export default defineComponent( {
    name: 'PartnersSwitch',
    components: { Partners_1 },
    props: {
        config: {
            type: Object as PropType<any>
        },
        type: {
            type: Number
        }
    },
    setup() {
    }
} );
