/* tslint:disable:max-classes-per-file */

import { Configuration, CustomError } from "@/api";
import { ConfigService } from "@/services/ConfigService";
import { BaseAPI } from "@/api/base";
import axios, { AxiosInstance } from "axios";
import { i18n } from "@/main";
import { NotificationsService } from "@/services/NotificationsService";
import { isObject } from "@/utils/Utils";

export abstract class ApiService {
    private static axiosInstance = axios.create();

    public static wrap<A extends BaseAPI>( TargetType: new ( config?: Configuration, basePath?: string, axiosInstance?: AxiosInstance ) => A ): A {
        const basePath = ConfigService.getHubBaseUrl();
        const config = new Configuration();
        //TODO: for custom lists
        // config.accessToken = store.state.auth.accessToken
        return new TargetType( config, basePath, ApiService.axiosInstance );
    }

    private static async customErrorHandler( response: CustomError ): Promise<CustomError> {
        if( response.humanMessage ) {
            NotificationsService.error( i18n.global.t( 'notification.error' ), i18n.global.t( response.humanMessage ) );
        } else {
            NotificationsService.error( i18n.global.t( 'notification.error' ), response.message );
        }
        return response;
    }

    public static async defaultErrorHandler( response: Response | Error ): Promise<Response | Error> {
        if( response instanceof Error && !( response instanceof TypeError ) ) {
            const r = <any> response;
            if( 'response' in r && isObject( r.response ) && 'data' in r.response ) {
                const e = r.response.data;
                if( isObject( e ) && 'code' in e && 'message' in e ) {
                    await ApiService.customErrorHandler( r.response.data );
                    return response;
                }
            }
            if( ( 'code' in r && r.code == "ECONNABORTED" ) || r.message == "Network Error" ) {
                NotificationsService.error( i18n.global.t( 'notification.error' ), i18n.global.t( 'notification.errors.whileCommunicatingWithServer' ) );
                return response;
            }
            if( ( 'response' in r && isObject( r.response ) && 'status' in r.response && r.response.status == '401' ) ||
                    response.message == "Request failed with status code 401" ) {
                NotificationsService.error( i18n.global.t( 'notification.error' ), response.toString() );
            }
        }
        NotificationsService.error( i18n.global.t( 'notification.error' ), response.toString() );
        console.log( response );
        return response;
    }
}