

import { defineComponent } from "vue";
import HTML_1 from "@/modules/html/HTML_1.vue";

export default defineComponent( {
    name: 'HTMLSwitch',
    components: { HTML_1 },
    props: {
        config: {
            type: Object
        },
        type: {
            type: Number
        }
    },
    setup() {
    }
} );
