

import { defineComponent, getCurrentInstance, onMounted, ref, Ref, watch } from "vue";
import JsonEditor from "@/modules/editor/JsonEditor.vue";
import Container_1 from "@/modules/container/Container_1.vue";
import WebsiteConfigurator from "@/modules/editor/WebsiteConfigurator.vue";
import { ClubApi, SinglePageConfig, SinglePageConfigResponse } from "@/api";
import { ApiService } from "@/services/ApiService";
import Toolbar from 'primevue/toolbar';
import Button from "primevue/button";
import { useI18n } from "vue-i18n";
import InputText from "primevue/inputtext";
import { NotificationsService } from "@/services/NotificationsService";
import FileUpload from "primevue/fileupload";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import ContainerSwitch from "@/modules/container/ContainerSwitch.vue";
import { i18n } from "@/main";

const mainConfig = require( '@/config/website.config.json' );
const schema = require( '@/api/singlePageConfig.json' );
const lodash = require( "lodash" );
const clubApi = ApiService.wrap( ClubApi );
const Ajv = require( "ajv" );
const ajv = new Ajv();


export default defineComponent( {
    name: 'Editor',
    components: {
        ContainerSwitch,
        WebsiteConfigurator,
        JsonEditor,
        Container_1,
        Toolbar,
        Button,
        InputText,
        FileUpload,
        DataTable,
        Column
    },
    props: {
        config: {
            type: Object
        }
    },
    setup( props ) {
        const i18n = useI18n();
        const minSidebarSize = '700px';
        const minPaneSize = '50px';
        const currConfig: Ref<SinglePageConfig> = ref( lodash.cloneDeep( props.config ) );
        const oldConfig: Ref<SinglePageConfig> = ref( lodash.cloneDeep( props.config ) );
        const jsonContent: Ref<Object> = ref( getDefaultContent() );
        const showCode: Ref<boolean> = ref( false );
        const showFullScreen: Ref<boolean> = ref( false );
        const updateWysiwyg: Ref<number> = ref( 0 );
        const updateCodeEditor: Ref<number> = ref( 0 );
        const scrollPosition: Ref<number> = ref( 0 );
        const styleElement: Ref<HTMLStyleElement> = ref( null );
        const history: Ref<SinglePageConfig[]> = ref( [] );
        const currIndex: Ref<number> = ref( -1 );
        const description: Ref<string> = ref( i18n.t( 'editor.defaultConfigDescription', { date: new Date().toLocaleDateString( i18n.t( "location" ) ) } ) );
        const backups: Ref<SinglePageConfigResponse[]> = ref( [] );

        //NotificationsService.info( i18n.t( 'notification.info' ), i18n.t( 'notification.infos.previewNoMenus' ), -1 );

        function getDefaultContent() {
            return {
                json: currConfig.value,
                text: undefined
            };
        }

        function changeCodeInJsonEditor( newCode ) {
            saveScrollPosition();
            if( newCode.json ) {
                currConfig.value = newCode.json;
            } else {
                try {
                    const updatedJson = JSON.parse( newCode.text );
                    if( updatedJson ) {
                        currConfig.value = updatedJson;
                    }
                } catch( syntaxError ) {

                }
            }
            updateWysiwyg.value += 1;

            if( currConfig.value.general.customCss ) {
                styleElement.value = document.createElement( 'style' );
                styleElement.value.innerText = currConfig.value.general.customCss;
                document.head.append( styleElement.value );
                console.log( styleElement.value, currConfig.value.general.customCss );
            } else if( styleElement.value ) {
                styleElement.value.remove();
                styleElement.value = null;
            }
        }

        function changeConfig( newConfig ) {
            saveScrollPosition();
            if( currIndex.value >= 0 && lodash.isEqual( history.value[currIndex.value], newConfig ) ) {
                return;
            }
            if( currIndex.value < 0 && lodash.isEqual( newConfig, oldConfig.value ) ) {
                return;
            }
            currConfig.value = lodash.cloneDeep( newConfig );
            jsonContent.value = getDefaultContent();
            updateCodeEditor.value += 1;
            const start = Math.max( 0, currIndex.value - 50 ); //history max length 50
            history.value = history.value.slice( start, currIndex.value + 1 );
            currIndex.value = history.value.push( lodash.cloneDeep( newConfig ) ) - 1;

            window.onbeforeunload = function() {
                return true;
            };
        }

        function reset( resetConfig: boolean = true ) {
            saveScrollPosition();
            currIndex.value = -1;
            history.value = [];
            if( resetConfig )
                currConfig.value = lodash.cloneDeep( oldConfig.value );

            window.onbeforeunload = function() {
                return null;
            };
            updateCodeEditor.value += 1;
            updateWysiwyg.value += 1;
        }

        function revert() {
            saveScrollPosition();
            currIndex.value++;
            if( currIndex.value >= history.value.length ) {
                currIndex.value = history.value.length - 1;
            }
            currConfig.value = history.value[currIndex.value];
            updateCodeEditor.value += 1;
            updateWysiwyg.value += 1;
        }

        function undo() {
            saveScrollPosition();
            currIndex.value--;
            if( currIndex.value < 0 ) {
                currConfig.value = lodash.cloneDeep( oldConfig.value );
            } else {
                currConfig.value = history.value[currIndex.value];
            }
            updateCodeEditor.value += 1;
            updateWysiwyg.value += 1;
        }

        function saveScrollPosition() {
            let element = document.getElementById( "ContainerPanel" );
            scrollPosition.value = element.lastElementChild.lastElementChild.scrollTop;
        }

        function hasConfigChanged(): boolean {
            return !lodash.isEqual( currConfig.value, oldConfig.value );
        }

        function publish() {
            if( hasConfigChanged() ) {
                clubApi.setSinglePageConfig( props.config.general.club, {
                    description: description.value,
                    data: currConfig.value
                } ).then( ( r ) => {
                    oldConfig.value = lodash.cloneDeep( r.data.data );
                    NotificationsService.success( i18n.t( "notification.success" ), i18n.t( "notification.successes.uploadComplete" ) );
                    reset();
                } ).catch( ( r ) => {
                    ApiService.defaultErrorHandler( r );
                    NotificationsService.error( i18n.t( "notification.error" ), i18n.t( "notification.errors.saveConfigManually" ) );
                } );
            }
        }

        function isLastHistoryItem() {
            return history.value.length <= currIndex.value + 1;
        }

        function saveCode() {
            return "data:text/json;charset=utf-8," + encodeURIComponent( JSON.stringify( currConfig.value ) );
        }

        function loadConfig( event ) {
            const reader = new FileReader();
            reader.onload = ( s ) => {
                try {
                    const validate = ajv.compile( schema );
                    const data = JSON.parse( s.target.result.toString() );
                    const valid = validate( data );
                    if( !valid ) {

                        throw Error;
                    }
                    currConfig.value = data;
                    updateCodeEditor.value += 1;
                    updateWysiwyg.value += 1;
                } catch {
                    NotificationsService.error( i18n.t( "notification.error" ), i18n.t( "notification.errors.configWrongFormat" ) );
                }
            };
            reader.readAsText( event.target.files[0] );
        }

        function getBackups() {
            clubApi.getSinglePageConfigBackups( props.config.general.club ).then( r => {
                backups.value = r.data;
            } ).catch( ApiService.defaultErrorHandler );
        }

        function loadBackup( backup: SinglePageConfigResponse ) {
            currConfig.value = lodash.cloneDeep( backup.data );
            updateCodeEditor.value += 1;
            updateWysiwyg.value += 1;
        }

        return {
            minPaneSize,
            minSidebarSize,
            jsonContent,
            currConfig,
            showCode,
            showFullScreen,
            mainConfig,
            updateWysiwyg,
            updateCodeEditor,
            currIndex,
            history,
            description,
            backups,
            scrollPosition,
            loadConfig,
            saveCode,
            changeConfig,
            changeCodeInJsonEditor,
            revert,
            undo,
            hasConfigChanged,
            isLastHistoryItem,
            publish,
            reset,
            getBackups,
            loadBackup
        };
    }
} );
