

import { defineComponent, getCurrentInstance, onMounted, onUpdated, PropType, ref, Ref, watch } from "vue";
import { IMenuItem } from "@/utils/interfaces/IMenuItem";
import Icon from "@/modules/general/Icon.vue";
import { IPage } from "@/utils/interfaces/IPage";
import { getMenu } from "@/utils/Utils";
import MenuLink from "@/modules/general/MenuLink.vue";
import { adjustMenuToWidth } from "@/utils/MenuUtils";
import HMenu from "@/modules/general/HMenu.vue";

export default defineComponent( {
    name: 'Menu_1',
    components: { HMenu, MenuLink, Icon },
    props: {
        pages: {
            type: Array as PropType<IPage[]>,
            default: () => []
        },
        additionalItems: {
            type: Array as PropType<IMenuItem[]>,
            default: () => []
        }
    },
    setup( props ) {
        const menuItems: Ref<IMenuItem[]> = ref( [] );
        const container: Ref<HTMLElement> = ref( null );

        onMounted( () => {
            menuItems.value = getMenu( props.pages ).concat( props.additionalItems );
            container.value = document.getElementById( "mainMenu" );
        } );

        return {
            menuItems,
            container
        };
    }
} );
