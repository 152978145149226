import { ConfigProp } from "@/config/ConfigProp";

const HEIGHT = "min(80rem,80vh)";
const MANUAL = true;

export class Gallery1Config {
    public static get Config(): { [key: string]: ConfigProp } {
        return {
            "images": {
                "type": "object",
                "format": "gallery"
            },
            "height": {
                "type": "string",
                "required": true,
                "default": HEIGHT,
                "preDefined": [ {
                    icon: {
                        name: "fullscreen-exit", source: "bi"
                    },
                    value: "min(20rem,20vh)"
                }, {
                    icon: {
                        name: "fullscreen", source: "bi"
                    },
                    value: "min(40rem,40vh)"
                }, {
                    icon: {
                        name: "arrows-fullscreen", source: "bi"
                    },
                    value: HEIGHT
                }
                ]
            },
            "maxWidth": {
                "type": "string",
                "preDefined": [ {
                    icon: {
                        name: "fullscreen-exit", source: "bi"
                    },
                    value: "1200px"
                }, {
                    icon: {
                        name: "fullscreen", source: "bi"
                    },
                    value: "1600px"
                }, {
                    icon: {
                        name: "arrows-fullscreen", source: "bi"
                    },
                    value: "100%"
                }
                ]
            },
            "autoSwitchTimer": {
                "type": "number",
                "format": "integer"
            },
            "manual": {
                "type": "boolean",
                "default": MANUAL,
                "label": "showNavigation"
            }
        };
    }

    public static Empty( i18n ) {
        return {
            type: "gallery",
            title: {},
            style: 1,
            name: i18n.t( 'common.unnamed' ),
            config: {
                height: HEIGHT,
                manual: MANUAL,
                images: []
            }
        };
    }
}