

import { defineComponent, onMounted, PropType, ref, Ref, watch } from "vue";
import { IUniqueMedia } from "@/utils/interfaces/IUniqueMedia";
import Image from 'primevue/image';
import Galleria from 'primevue/galleria';
import GalleryModal from "@/modules/editor/GalleryModal.vue";
import Button from "primevue/button";
import Modal from "bootstrap/js/dist/modal";

const lodash = require( "lodash" );

export default defineComponent( {
    name: 'GalleryConfigurator',
    components: {
        GalleryModal,
        Image,
        Galleria,
        Button
    },
    props: {
        value: {
            type: Array as PropType<IUniqueMedia[]>
        },
        id: {
            type: String
        }
    },
    emits: [ "change" ],
    setup( props, { emit } ) {
        const currImages: Ref<IUniqueMedia[]> = ref( [] );
        const idx: Ref<number> = ref( 0 );
        const insertId: Ref<number> = ref( null );

        onMounted( () => {
            if( props.value ) {
                currImages.value = [];
                idx.value = 0;
                for( const val of props.value ) {
                    let img = lodash.cloneDeep( val );
                    img.positionId = idx.value++;
                    currImages.value.push( img );
                }
            }
        } );

        function toLeft( positionId: number ) {
            let oldIdx = currImages.value.findIndex( i => i.positionId == positionId );
            let img1 = currImages.value[oldIdx];
            let img2 = currImages.value[oldIdx - 1];
            currImages.value[oldIdx - 1] = img1;
            currImages.value[oldIdx] = img2;
            change();
        }

        function deleteImg( positionId: number ) {
            currImages.value = currImages.value.filter( i => i.positionId != positionId );
            change();
        }

        function insertImg( imgs: IUniqueMedia[] ) {
            for( const img of imgs ) {
                img.positionId = idx.value++;
            }
            let insertIdx = 0;
            if( insertId.value != null ) {
                insertIdx = currImages.value.findIndex( i => i.positionId == insertId.value ) + 1;
            }
            currImages.value.splice( insertIdx, 0, ...imgs );
            change();
        }

        function setInsertId( positionId: number = null ) {
            insertId.value = positionId;
            let myModal = new Modal( document.getElementById( props.id + 'Selector' ) );
            myModal.toggle();
            change();
        }

        function toRight( positionId: number ) {
            let oldIdx = currImages.value.findIndex( i => i.positionId == positionId );
            let img1 = currImages.value[oldIdx];
            let img2 = currImages.value[oldIdx + 1];
            currImages.value[oldIdx + 1] = img1;
            currImages.value[oldIdx] = img2;
            change();
        }

        function change() {
            let currValue = lodash.cloneDeep( currImages.value );
            currValue.forEach( i => delete i.positionId );
            if( !lodash.isEqual( currValue, props.value ) ) {
                emit( "change", currValue );
            }
        }


        return {
            currImages,
            toLeft,
            deleteImg,
            toRight,
            insertImg,
            setInsertId
        };
    }
} );
