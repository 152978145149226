import { ConfigProp } from "@/config/ConfigProp";

const IMG_POS = "right";
const IMG_HEIGHT_EM = 9;
const DEFAULT_BG = "";

export class Html1Config {
    public static get Config(): { [key: string]: ConfigProp } {
        return {
            "html": {
                "type": "string",
                "format": "html",
                "fullWidth": true
            },
            "bgColor": {
                "type": "string",
                "required": true,
                "default": DEFAULT_BG,
                "format": "color"
            },
            "maxWidth": {
                "type": "string",
                "preDefined": [ {
                    icon: {
                        name: "fullscreen-exit", source: "bi"
                    },
                    value: "min(1200px,100%)"
                }, {
                    icon: {
                        name: "fullscreen", source: "bi"
                    },
                    value: "min(1600px,100%)"
                }, {
                    icon: {
                        name: "arrows-fullscreen", source: "bi"
                    },
                    value: "100%"
                }
                ]
            },
            "image": {
                "type": "object",
                "format": "image"
            },
            "imgPosition": {
                "label": "position",
                "type": "string",
                "format": "enum",
                "default": IMG_POS,
                "values": [
                    "left",
                    "right"
                ],
            },
            "imgHeightEm": {
                "label": "heightEm",
                "type": "number",
                "format": "decimal",
                "default": IMG_HEIGHT_EM,
            }
        };
    }

    public static Empty( i18n ) {
        return {
            type: "html",
            title: {},
            style: 1,
            name: i18n.t( 'common.unnamed' ),
            config: {
                imgPosition: IMG_POS,
                imgHeightEm: IMG_HEIGHT_EM,
                bgColor: DEFAULT_BG
            }
        };
    }
}