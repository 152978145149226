export enum SiteType{
    LegalNotice,
    Main,
    PrivacyStatement
}

export function getSiteTypeByPath(): SiteType {
    switch(decodeURI(window.location.pathname)) {
        case "/legal-notice":
        case "/impressum":
            return SiteType.LegalNotice
        case "/privacy-statement":
        case "/datenschutzerklärung":
            return SiteType.PrivacyStatement
        default:
            return SiteType.Main
    }
}