

import { defineComponent, getCurrentInstance, onMounted, ref, Ref } from "vue";
import { getSiteTypeByPath, SiteType } from "@/utils/SiteType";
import Container_1 from "@/modules/container/Container_1.vue";
import Editor from "@/modules/editor/Editor.vue";
import ContainerSwitch from "@/modules/container/ContainerSwitch.vue";

export default defineComponent( {
    name: 'App',
    components: { ContainerSwitch, Editor },
    props: {},
    setup: function() {
        const app = getCurrentInstance();
        const config = app.appContext.config.globalProperties.config;
        const editor: Ref<boolean> = ref( false );
        document.title = config.general.title;

        onMounted( () => {
            editor.value = document.location.pathname.startsWith( '/edit' ); //TODO: check if user is allowed
            app.appContext.config.globalProperties.editor = editor.value;
        } );

        return {
            config,
            editor
        };
    }
} );
