/* eslint @typescript-eslint/no-var-requires: "off" */
import { createApp, ref } from 'vue';
import App from './App.vue';
import { createI18n } from "vue-i18n/index.mjs";
import messages from '@/i18n/messages';
import { ConfigService } from "@/services/ConfigService";
import SplitCarousel from "vue-split-carousel";
import PrimeVue from 'primevue/config';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Tooltip from 'primevue/tooltip';
import ConfigProperty from "@/modules/editor/ConfigProperty.vue";

const config = require( '@/config/config.json' );

const app = createApp( App );

export const i18n = createI18n( {
    locale: ConfigService.getFallbackLang(), //TODO: get lang of user
    messages,
    fallbackLocale: ConfigService.getFallbackLang(),
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    legacy: false
} );
app.use( i18n );
app.use( SplitCarousel );
app.use( PrimeVue );
app.component( "Splitter", Splitter );
app.component( "SplitterPanel", SplitterPanel );
app.component( "ConfigProperty", ConfigProperty );
app.config.globalProperties.config = config;
app.config.globalProperties.editor = false;
app.config.globalProperties.editorPage = ref( "" );
app.config.globalProperties.editorElement = ref( "" );
app.config.globalProperties.openModalEditor = ref( -1 );
app.directive( 'tooltip', Tooltip );

app.directive( 'click-outside', {
    mounted( el, binding, vnode ) {
        el.clickOutsideEvent = function( event ) {
            if( !( el === event.target || el.contains( event.target ) ) ) {
                binding.value( event, el );
            }
        };
        document.body.addEventListener( 'click', el.clickOutsideEvent );
    },
    unmounted( el ) {
        document.body.removeEventListener( 'click', el.clickOutsideEvent );
    }
} );

app.mount( '#app' );