

import { defineComponent, getCurrentInstance, onMounted, PropType, Ref, ref } from "vue";
import { adjustMenuToWidth } from "@/utils/MenuUtils";
import { IMenuItem } from "@/utils/interfaces/IMenuItem";
import MenuLink from "@/modules/general/MenuLink.vue";
import Icon from "@/modules/general/Icon.vue";
import { nanoid } from "nanoid";
import Offcanvas from "bootstrap/js/dist/offcanvas";

export default defineComponent( {
    name: 'HMenu',
    components: { MenuLink, Icon },
    props: {
        menuItems: {
            type: Object as PropType<IMenuItem[]>,
            default: () => []
        },
        container: {
            type: Object as PropType<HTMLElement>,
            default: () => {
            }
        }
    },
    setup( props ) {
        const rndId = ref( "id" + nanoid() );
        const menu = ref<HTMLElement | null>( null );
        const hide = ref<boolean>( true );
        const displayItems: Ref<IMenuItem[]> = ref( [] );
        const hiddenItems: Ref<IMenuItem[]> = ref( [] );
        const app = getCurrentInstance();
        const editor: boolean = app.appContext.config.globalProperties.editor;

        onMounted( () => {
            if( props.container ) {
                const observer = new ResizeObserver( adjustMenu );
                observer.observe( props.container );
            }

            adjustMenu();
        } );

        let timeout = null;

        function adjustMenu() {
            hide.value = true;
            displayItems.value = props.menuItems;
            if( timeout ) {
                clearTimeout( timeout );
            }
            timeout = setTimeout( () => {
                const result = adjustMenuToWidth( props.menuItems, menu.value );
                displayItems.value = result.first;
                hiddenItems.value = result.second;
                hide.value = false;
            }, 200 );
        }

        function closeCanvas() {
            const offcanvasButtonEl = document.getElementById( 'offcanvasBtn' + rndId.value );
            offcanvasButtonEl.click();
        }

        return {
            rndId,
            menu,
            hide,
            displayItems,
            hiddenItems,
            editor,
            closeCanvas
        };
    }
} );
