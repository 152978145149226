

import { defineComponent, PropType } from "vue";
import EqualGrid from "@/modules/general/EqualGrid.vue";

interface IPartner {
    name: string,
    link: string,
    image: any
}

interface IPartners {
    maxWidth: string;
    bgColor: string;
    partners: IPartner[];
}

export default defineComponent( {
    name: 'Partners_1',
    components: { EqualGrid },
    props: {
        config: {
            type: Object as PropType<IPartners>
        },
        id: {
            type: String
        }
    },
    setup() {
    }
} );
