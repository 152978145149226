

import { defineComponent, PropType } from "vue";
import { IIcon } from "@/utils/interfaces/IIcon";

export default defineComponent( {
    name: 'Icon',
    components: {},
    props: {
        icon: {
            type: Object as PropType<IIcon>
        }
    },
    setup() {
    }
} );
