

import { defineComponent, onMounted, onUpdated, ref, Ref } from "vue";

export default defineComponent( {
    name: 'EqualGrid',
    components: {},
    props: {
        id: {
            type: String
        }
    },
    setup( props: any ) {
        const placeholderCount: Ref<number> = ref( 0 );
        const childWidth: Ref<number> = ref( 0 );
        const center: Ref<boolean> = ref( false );
        const element: Ref<HTMLElement> = ref( null );

        onMounted( () => {
            element.value = document.getElementById( props.id );
            calculateElements();

            if( element.value ) {
                const observer = new ResizeObserver( calculateElements );
                observer.observe( element.value );
            }
        } );

        function calculateElements() {

            if( !element.value ) {
                return;
            }
            const child = element.value.children.item( 0 );
            if( !child ) {
                return;
            }
            const style = window.getComputedStyle( child );
            childWidth.value = child.clientWidth + parseInt( style.marginLeft, 10 ) + parseInt( style.marginRight, 10 );
            const childCount = element.value.childElementCount;
            const parentWidth = element.value.clientWidth;
            const elementsPerRow = Math.floor( parentWidth / childWidth.value );
            placeholderCount.value = elementsPerRow - childCount % elementsPerRow;
            center.value = childCount != 1 && elementsPerRow == 1;
        }

        return {
            placeholderCount,
            childWidth,
            center
        };
    }
} );
