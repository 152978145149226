import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EqualGrid = _resolveComponent("EqualGrid")!

  return (_openBlock(), _createElementBlock("div", {
    class: "partners-1 d-flex",
    id: _ctx.id,
    style: _normalizeStyle({backgroundColor: _ctx.config.bgColor})
  }, [
    _createElementVNode("div", {
      class: "mx-auto",
      style: _normalizeStyle({maxWidth: _ctx.config.maxWidth})
    }, [
      _createVNode(_component_EqualGrid, {
        id: _ctx.id + 'Grid'
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.partners, (partner) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(partner.link ? 'a' : 'div'), {
              href: partner.link,
              key: partner,
              target: "_blank",
              rel: "noopener noreferrer",
              class: "partner-card-1 mw-100"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "p-3 mw-100",
                  src: partner.image?.itemSrc,
                  alt: partner.image?.alt
                }, null, 8, _hoisted_2),
                _createElementVNode("span", null, _toDisplayString(partner.name), 1)
              ]),
              _: 2
            }, 1032, ["href"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["id"])
    ], 4)
  ], 12, _hoisted_1))
}