

import { defineComponent, onMounted, ref, Ref, watch } from "vue";
import { IUniqueMedia } from "@/utils/interfaces/IUniqueMedia";
import InputText from "primevue/inputtext";
import Image from "primevue/image";
import Checkbox from "primevue/checkbox";

export default defineComponent( {
    name: 'GalleryModal',
    components: {
        InputText,
        Image,
        Checkbox
    },
    emits: [ "select" ],
    props: {
        id: {
            type: String
        },
        multiple: {
            type: Boolean,
            default: () => false
        }
    },
    setup( props, { emit } ) {
        const imgs: Ref<IUniqueMedia[]> = ref( [] );
        const currImgs: Ref<IUniqueMedia[]> = ref( [] );
        const selectedImgIds: Ref<string[]> = ref( [] );
        const loading: Ref<boolean> = ref( true );
        const search: Ref<string> = ref( "" );
        const tags: Ref<any[]> = ref( [] );
        const currTags: Ref<any[]> = ref( [] );
        const selectedTags: Ref<string[]> = ref( [] );

        function getTags() {
            let tagsTmp = {};
            for( const img of imgs.value ) {
                if( img.tags ) {
                    for( const tag of img.tags ) {
                        if( tagsTmp[tag] ) {
                            tagsTmp[tag] += 1;
                        } else {
                            tagsTmp[tag] = 1;
                        }
                    }
                }
            }
            tags.value = Object.keys( tagsTmp ).map( function( key ) {
                return [ key, tagsTmp[key] ];
            } );

            tags.value.sort( function( first, second ) {
                return second[1] - first[1];
            } );
        }

        watch( search, ( newValue, oldValue ) => {
            currTags.value = tags.value.filter( t => t[0].includes( newValue ) );
            currImgs.value = imgs.value.filter( img => img.alt.includes( newValue ) || img.title.includes( newValue ) );
        } );

        onMounted( () => {
            loading.value = true;
            imgs.value = getImages();
            getTags();
            currTags.value = tags.value;
            currImgs.value = imgs.value;
            loading.value = false;
        } );

        function select() {
            let result = imgs.value.filter( img => selectedImgIds.value.includes( img.id ) );
            if( props.multiple ) {
                emit( "select", result );
            } else {
                emit( "select", result[0] );
            }

            clean();
        }

        function clean() {
            selectedImgIds.value = [];
        }

        function toggleTag( id: string ) {
            if( selectedTags.value.includes( id ) ) {
                selectedTags.value = selectedTags.value.filter( t => t != id );
            } else {
                selectedTags.value.push( id );
            }

            search.value = "";
            if( selectedTags.value.length > 0 ) {
                currImgs.value = imgs.value.filter( img => img.tags && img.tags.some( t => selectedTags.value.includes( t ) ) );
            } else {
                currImgs.value = imgs.value;
            }
        }

        function singleChoiceHandler( value ) {
            if( !props.multiple && Array.isArray( value ) && value.length != 0 ) {
                selectedImgIds.value = [ value[value.length - 1] ];
            }
        }

        //Mocking
        function getImages(): IUniqueMedia[] {
            return [ {
                "itemSrc": "https://picsum.photos/800/920",
                "thumbnailImageSrc": "https://picsum.photos/200/302",
                "alt": "Description for Image 8",
                "title": "Title 8",
                "type": "image",
                "id": "as2"
            },
                {
                    "itemSrc": "https://picsum.photos/910/500",
                    "thumbnailImageSrc": "https://picsum.photos/200/301",
                    "alt": "Description for Image 9",
                    "title": "Title 9",
                    "type": "image",
                    "id": "as21",
                    "tags": [ "Bilder2022", "Posaune" ]
                },
                {
                    "itemSrc": "https://picsum.photos/900/300",
                    "thumbnailImageSrc": "https://picsum.photos/1200/300",
                    "alt": "Description for Image 10",
                    "title": "Title 10",
                    "type": "image",
                    "id": "as22",
                    "tags": [ "Bilder2022", "Posaune" ]
                },
                {
                    "itemSrc": "https://picsum.photos/800/920",
                    "thumbnailImageSrc": "https://picsum.photos/200/302",
                    "alt": "Description for Image 8",
                    "title": "Title 8",
                    "type": "image",
                    "id": "as23",
                    "tags": [ "Bilder2021", "Posaune" ]
                },
                {
                    "itemSrc": "https://picsum.photos/810/900",
                    "thumbnailImageSrc": "https://picsum.photos/200/301",
                    "alt": "Description for Image 9",
                    "title": "Title 9",
                    "type": "image",
                    "id": "as24",
                    "tags": [ "Bilder2022", "Trompete" ]
                },
                {
                    "itemSrc": "https://picsum.photos/800/900",
                    "thumbnailImageSrc": "https://picsum.photos/200/300",
                    "alt": "Description for Image 10",
                    "title": "Title 10",
                    "type": "image",
                    "id": "as25",
                    "tags": [ "Bilder2022", "Posaune" ]
                },
                {
                    "itemSrc": "https://picsum.photos/800/920",
                    "thumbnailImageSrc": "https://picsum.photos/200/302",
                    "alt": "Description for Image 8",
                    "title": "Title 8",
                    "type": "image",
                    "id": "as26",
                    "tags": [ "Bilder2021", "Posaune" ]
                },
                {
                    "itemSrc": "https://picsum.photos/810/900",
                    "thumbnailImageSrc": "https://picsum.photos/200/301",
                    "alt": "Description for Image 9",
                    "title": "Title 9",
                    "type": "image",
                    "id": "as27",
                    "tags": [ "Bilder2022", "Trompete" ]
                },
                {
                    "itemSrc": "https://picsum.photos/800/900",
                    "thumbnailImageSrc": "https://picsum.photos/200/300",
                    "alt": "Description for Image 10",
                    "title": "Title 10",
                    "type": "image",
                    "id": "as28",
                    "tags": [ "Bilder2022", "Posaune" ]
                } ];
        }

        return {
            loading,
            imgs,
            currImgs,
            selectedImgIds,
            currTags,
            search,
            selectedTags,
            toggleTag,
            select,
            clean,
            singleChoiceHandler
        };
    }
} );
