import { ConfigProp } from "@/config/ConfigProp";

export class MenuItemConfig {
    public static get Config(): { [key: string]: ConfigProp } {
        return {
            "name": {
                "type": "string",
                "required": true
            },
            "display": {
                "label": "display.name",
                "required": true,
                "default": true,
                "type": "boolean"
            },
            "icon": {
                "type": "object",
                "format": "icon",
                "required": false
            },
            "link": {
                "type": "string",
                "format": "url",
                "required": true
            }
        };
    }
}