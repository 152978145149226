

import { defineComponent, onBeforeUpdate, onMounted, onUnmounted, onUpdated, ref, Ref } from "vue";
import Container_1 from "@/modules/container/Container_1.vue";

export default defineComponent( {
    name: 'ContainerSwitch',
    components: { Container_1 },
    props: {
        config: {
            type: Object
        },
        scrollPosition: {
            type: Number,
            default: () => 0
        }
    },
    setup( props ) {
        const loading: Ref<boolean> = ref( false );

        onMounted( () => {
            let element = document.getElementById( "Container" );
            if( props.scrollPosition != 0 ) {
                loading.value = true;
                setTimeout( () => {
                    loading.value = false;
                    element.lastElementChild.scrollTo( 0, props.scrollPosition );
                }, 200 );
            }
        } );

        return {
            loading
        };
    }
} );
